import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux"; 
import { IStateType } from "../../store/models/root.interface";
import _ from "lodash"; 
import "./LeftMenu.scss";
const LeftMenu: React.FC = () => {
  
  let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);
  const user: string = useSelector((state: IStateType) => state.account.user);


  function changeLeftMenuVisibility() {
    setLeftMenuVisibility(!leftMenuVisibility);
  }

  function getCollapseClass() {
    return leftMenuVisibility ? "" : "collapsed";
  }

  return (
    <Fragment>
      <div className="toggle-area">
        <button
          className="btn btn-primary toggle-button"
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className="fas fa-user-circle"></i>
        </button>
      </div>

      <ul
        className={`navbar-nav cuerpo sidebar sidebar-dark accordion ${getCollapseClass()}`} 
        id="collapseMenu"
      >
        <a
          className="sidebar-brand align-items-center justify-content-center"
          href="home"
        >
          <div className="sidebar-brand-icon icon-gray">
            <i className="fas fa-user-circle"></i>
          </div>
          <div className="sidebar-brand-text mx-3">Hola, {_.get(user, "name", "")}</div>
        </a>

        <li className="nav-item active pt-5">
        <Link className="nav-link" to={`/home`}>
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/users`}>
            <i className="fas fa-fw fa-user-shield"></i>
            <span>Administrar Usuarios</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/simcards`}>
            <i className="fas fa-fw fa-file-alt"></i>
            <span>Reporte de Sim Cards</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/payments`}>
            <i className="fas fa-fw fa-file-alt"></i>
            <span>Pagos</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/commissions`}>
            <i className="fas fa-fw fa-file-alt"></i>
            <span>Actualizar comisiones</span>
          </Link>
        </li>
{/*         <li className="nav-item">
          <Link className="nav-link" to={`/commissions`}>
            <i className="fas fa-fw fa-file-alt"></i>
            <span>Reportes comisiones</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link className="nav-link" to={`/news`}>
            <i className="fas fa-fw fa-newspaper"></i>
            <span>Noticias</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/dealers`}>
            <i className="fas fa-fw fa-user-friends"></i>
            <span>Dealers</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/map`}>
            <i className="fas fa-fw fa-map"></i>
            <span>Usuarios por ubicación</span>
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

export default LeftMenu;
