import React, { Fragment, Dispatch, useState, useEffect } from "react";
import SimCardReportList from "./SimCardReportList";
import SimCardReportForm from "./SimCardReportForm";
import TopCard from "../../common/components/TopCard";
import "./SimCardReport.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  ISimCardState,
  IStateType,
  IRootPageStateType
  
} from "../../store/models/root.interface";
import { ISimCardParameter } from "../../store/models/simcard.interface";
import Popup from "reactjs-popup";
import {
  removeSimCard,
  clearSelectedSimCard,
  setModificationState,
  changeSelectedSimCard,
  listsimcards,
  ExportExcel,
} from "../../store/actions/simcard.action";
import { addNotification } from "../../store/actions/notifications.action";
import {
  SimCardModificationStatus,
  ISimCard,
} from "../../store/models/simcard.interface";
import _, { map } from "lodash";
import Loading from "../Utilities/Loading";

const Simcards: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const simcards: ISimCardState = useSelector(
    (state: IStateType) => state.simcards
  );
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const user: string = useSelector((state: IStateType) => state.account.user);

  const statusOptions = [  { value: 1, label: 'Activas' },  { value: 2, label: 'Inactivas' }];

  const yearOptions = [ { value: 2021, label: '2021' },  { value: 2022, label: '2022' },  { value: 2023, label: '2023' },  { value: 2024, label: '2024' },  { value: 2025, label: '2025' }];
  
  const monthOptions = [  { value: 1, label: 'Enero' },  { value: 2, label: 'Febrero' },  { value: 3, label: 'Marzo' },  { value: 4, label: 'Abril' },  { value: 5, label: 'Mayo' },  { value: 6, label: 'Junio' },  { value: 7, label: 'Julio' },  { value: 8, label: 'Agosto' },  { value: 9, label: 'Septiembre' },  { value: 10, label: 'Octubre' },  { value: 11, label: 'Noviembre' },  { value: 12, label: 'Diciembre' }];
  
  const commissionOptions = [  { value: 1, label: 'Con comisión' },  { value: 0, label: 'Sin comisión' },];
  
  const channelOptions = [  { value: 1, label: 'Mixtos' },  { value: 2, label: 'Busitos' },  { value: 3, label: 'D2D' },  { value: 4, label: 'Tropas' },  { value: 5, label: 'DTT' }];
  



  const [visible, setvisible] = useState<Boolean>(false);
  const [status, setStatus] = useState<number>(statusOptions[0].value);
  const [year, setYear] = useState<number>(yearOptions[2].value);
  const [month, setMonth] = useState<number>(monthOptions[0].value);
  const [commission, setCommission] = useState<number>(commissionOptions[0].value);
  const [channel, setChannel] = useState<number>(channelOptions[0].value);








  const numberItemsCount: number = simcards.simcards.length;

  const [popup, setPopup] = useState(false);
  const [parametros, setparametros] = useState<ISimCardParameter>({
    'guid':_.get(user, "guid", ""),
    'estado':status,
    'anio':year,
    'mes':month,
    'comision':commission,
    'canal':channel,
  })

  useEffect(() => {
    setvisible(true);
    
    dispatch(listsimcards(parametros));
  }, []);


  useEffect(() => {
    setparametros(
      {
        'guid':_.get(user, "guid", ""),
        'estado':status,
        'anio':year,
        'mes':month,
        'comision':commission,
        'canal':channel,
      }
    );
  
  }, [status,year,month,commission,channel])
  

 function buscarSimcards(){
  setvisible(true);
  console.log('envio json  ----',parametros)
 
  dispatch(listsimcards(parametros));
 }




  useEffect(() => {
    if (simcards.simcards) {
      if (visible) {
        setvisible(false);
      }
      
    }
  }, [simcards.simcards]);

  useEffect(() => {
    dispatch(clearSelectedSimCard());
    dispatch(updateCurrentPath("simcards", "list"));
  }, [path.area, dispatch]);

  function onSimCardSelect(simcard: ISimCard): void {
    dispatch(changeSelectedSimCard(simcard));
    dispatch(setModificationState(SimCardModificationStatus.None));
  }

  function onUsuarioRemove() {
    if (simcards.selectedSimCard) {
      setPopup(true);
    }
  }

  function DownladExcel() {
    console.log('envio json downlod ----',parametros)
    dispatch(ExportExcel(parametros));
  }

  const simcardsCargadas: ISimCardState = useSelector(
    (state: IStateType) => state.simcards
  );


  function SelectForm() {

  
    return (
      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4">
          <form>
            <div className="form-row align-items-center">
              <div className="form-group col-md-2">
                <label htmlFor="status">Estado</label>
                <select
                  className="form-control"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(parseInt(e.target.value, 10))}
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="year">Año</label>
                <select
                  className="form-control"
                  id="year"
                  value={year}
                  onChange={(e) => setYear(parseInt(e.target.value, 10))}
                >
                  {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="month">Mes</label>
                <select
                  className="form-control"
                  id="month"
                  value={month}
                  onChange={(e) => setMonth(parseInt(e.target.value, 10))}
                >
                  {monthOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="commission">Comisión</label>
                <select
                  className="form-control"
                  id="commission"
                  value={commission}
                  onChange={(e) => setCommission(parseInt(e.target.value, 10))}
                >
                  {commissionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="channel">Canal</label>
                <select
                  className="form-control"
                  id="channel"
                  value={channel}
                  onChange={(e) => setChannel(parseInt(e.target.value, 10))}
                >
                  {channelOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="channel" style={{ visibility: 'hidden', display: 'inline-block' }}>------</label>
                <button
                  className="btn btn-lg top-button mr-2 float-right"
                  
                  onClick={buscarSimcards}
                >
                  <i className="fas fa-search icon-blue"></i> Buscar 
                </button> 
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Reporte de Sim Card</h1>
      
      <div className="row">
     
        <div className="col-xl-12 col-lg-12 mb-4">
        <SelectForm/>

        {!visible ?(
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button
            className="btn btn-lg top-button mr-2 float-right"
            onClick={DownladExcel}
          >
            <i className="fas fa-file-excel icon-green"></i> Descargar Excel
          </button>
        </div>
          )
        :null
        }
        </div>
      </div>
      
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Listado</h6>
              <div className="header-buttons"></div>
            </div>
            <div className="card-body">
              <SimCardReportList onSelect={onSimCardSelect} />
            </div>
          </div>
        </div>
        {simcards.modificationState === SimCardModificationStatus.Create ||
        (simcards.modificationState === SimCardModificationStatus.Edit &&
          simcards.selectedSimCard) ? (
          <SimCardReportForm />
        ) : null}
      </div>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Are you sure?</div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!simcards.selectedSimCard) {
                  return;
                }
                dispatch(
                  addNotification(
                    "SimCard removed",
                    `SimCard ${simcards.selectedSimCard.vendedor} was removed`
                  )
                );
                dispatch(removeSimCard(simcards.selectedSimCard.id));
                dispatch(clearSelectedSimCard());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
      <Loading visible={visible} setvisible={setvisible} />
    </Fragment>
  );
};

export default Simcards;
