import { IPaymentState, IActionBase } from "../models/root.interface";
import { ADD_PAYMENT, CHANGE_PAYMENT_PENDING_EDIT, EDIT_PAYMENT, REMOVE_PAYMENT,
    CLEAR_PAYMENT_PENDING_EDIT, SET_MODIFICATION_STATE, CHANGE_PAYMENT_COMISION , UPDATE_PAYMENT_DETAILS , CLEAN_NEW_PAYMENT, CLEAN_UPLOAD_FILE, LIST_PAYMENTS} from "../actions/payments.action";
import { IPayment, PaymentModificationStatus } from "../models/payment.interface";



const initialState: IPaymentState = {
    modificationState: PaymentModificationStatus.None,
    selectedPayment: null,
    payments: [], 
    payment: null, 
    uploadfile: false,
};

function paymentsReducer(state: IPaymentState = initialState, action: IActionBase): IPaymentState {
    switch (action.type) {
        case ADD_PAYMENT: {

            return { ...state, payment: action.payment};
        }
        case EDIT_PAYMENT: {
          
            return { ...state, payments: action.payments };
        }
        case LIST_PAYMENTS: { 
            return {...state, payments:action.payments }
        }

        case CLEAN_UPLOAD_FILE: {
          
            return { ...state, uploadfile: false};
        }
        case REMOVE_PAYMENT: {
            return { ...state, payments: state.payments.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_PAYMENT_PENDING_EDIT: {
            return { ...state, selectedPayment: action.payment };
        }
        case CLEAR_PAYMENT_PENDING_EDIT: {
            return { ...state, selectedPayment: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }

        case UPDATE_PAYMENT_DETAILS: {
          return {...state, uploadfile: action.uploadfile}
        }
        case CLEAN_NEW_PAYMENT:  { 
            return {...state, payment: null}
        }
        case CHANGE_PAYMENT_COMISION: {
  
            return { ...state, payments: action.payments };
        }
        default:
            return state;
    }
}


export default paymentsReducer;
