import React from "react";
import { useSelector } from "react-redux";
import { IStateType, IDealerState } from "../../store/models/root.interface";
import { IDealer } from "../../store/models/dealer.interface";
/* import moment from "moment"; */
import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';

import { GRID_DEFAULT_LOCALE_TEXT } from "../Utilities/LocaleDataGrid";
 import { Grid } from "@material-ui/core"; 
import EditIcon from "@material-ui/icons/Edit";

export type DealerListProps = {
  onSelect?: (dealer: IDealer) => void;
  children?: React.ReactNode;
};

function DealerList(props: DealerListProps): JSX.Element {
  const dealers: IDealerState = useSelector(
    (state: IStateType) => state.dealers
  );

  const dealerElements: (JSX.Element | null)[] = dealers.dealerList.map(
    (dealer) => {
      if (!dealer) {
        return null;
      }
      return (
        <tr className={"table-row"} key={`dealer_${dealer.codigovendedor}`}>
          <th scope="row">{dealer.codigovendedor}</th>
          <td>{dealer.nombre}</td>
          <td>{dealer.provincia}</td>
          <td>{dealer.distrito}</td>
          <td>{dealer.coordinador}</td>
          <td>{dealer.canal}</td>
          <td>
            {" "}
            <Link to={`/editdealers`}>
              <BsPencil
                onClick={() =>
                  localStorage.setItem("editdealers", JSON.stringify(dealer))
                }
              />{" "}
            </Link>
          </td>
        </tr>
      );
    }
  );

  const columns = [
    {
      field: "codigovendedor",
      headerName: "#",
      width: 200,
    },
    {
      field: "nombre",
      headerName: "Nombre Local",
      width: 250,
    },
    {
      field: "provincia",
      headerName: "Provincia",
      width: 200,
    },
    {
      field: "distrito",
      headerName: "Distrito",
      width: 200,
    },
    {
      field: "coordinador",
      headerName: "Coordinador",
      width: 200,
    },

    {
      field: "canal",
      headerName: "Canal",
      width: 120,
    },
    {
      field: "id",
      headerName: "Acciones",
      width: 120,
      renderCell: (params: { value: any }) => (
        <Link
          to={{
            pathname: `/editdealers`,
            state: { id: params.value },
          }}
        >
          <EditIcon style={{ fontSize: 25 }} color="primary" />
        </Link>
      ),
      sortable: false,
    },
  ];

  return (
    <div className="table-responsive portlet">
      <Grid container direction="column" style={{ height: "80vh" }}>
        
            <DataGrid 
            rows={dealers.dealerList} 
            columns={columns} 
            autoPageSize={true} 
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            onCellClick={(params, event) => {
              localStorage.setItem("editdealers", JSON.stringify(params.row))
 
          }}
           // {...data}
            />
     
      </Grid>
    </div>
  );
}

export default DealerList;
