import React, { useState, useEffect } from "react";
import _, { head, isEmpty, size } from "lodash";

import { getDealerbyId } from "../../store/actions/dealers.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utilities/Loading";
import { IStateType } from "../../store/models/root.interface";
import { setHideError } from "../../store/actions/errorActions";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { Card ,Button} from 'react-bootstrap';
import {
  ExportSimcardsPagada

} from "../../store/actions/payments.action";
const initialstate = {
  canal: "Mixto",
  canal_id: 1,
  categoria: "",
  codigovendedor: "",
  coordinador: "",
  corregimineto_id: 1,
  corregimiento: "",
  created_at: new Date(),
  distrito: "",
  distrito_id: 1,
  encargado: "",
  id: 27,
  latitud: "",
  longitud: "",
  nombre: "",
  provincia: "",
  provincia_id: 1,
  qr: "",
  ruc: "",
  ruta: "",
  state_id: 1,
  telefono: "",
  tipocomercio_id: 1,
  updated_at: "",
};

export default function SimcardsPagadas() {
  const [state, setstate] = useState(initialstate);

  const user: string = useSelector((state: IStateType) => state.account.user);
  const dealer: any = useSelector((state: IStateType) => state.dealers.dealer);
  const [visible, setvisible] = useState(false);

  const [pagoId,setPagoId]=useState(null);
  const dispatch = useDispatch();

  const notify = () => toast.warn("Su archivo se esta descargando,espere un momento");

  useEffect(() => {
    dispatch(setHideError());

    const id = localStorage.getItem("simcardspagadas");
    const jsonpago = JSON.parse(id);
    setPagoId(jsonpago);
    dispatch(getDealerbyId(_.get(user, "guid", ""), jsonpago.id));
    setstate(jsonpago);
    (async () => {

    })();
  }, []);

  useEffect(() => {
    if (dealer) {
 
      //console.log(dealer);
      setstate({ ...dealer, corregimiento: "" });
    }
  }, [dealer]);


  function DownladExcel() {
    notify();
    dispatch(ExportSimcardsPagada(_.get(user, "guid", ""), pagoId));
    
  }






  return (



/*     <div style={{ marginLeft: 20, marginRight: 20 }}>
      <h3>Simcards Pagadas comision</h3>
        <br />
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-4">
              <button
                className="btn btn-lg top-button mr-2 float-right"
                onClick={DownladExcel}
              >
                <i className="fas fa-file-excel icon-green"></i> Descargar cabecera de pagos
              </button>
            </div>
        </div>
        <ToastContainer />
    </div>
 */
<div>
<div className="container-fluid container-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center cardRounded">
              <div className="card-body">
                <h3 className="card-title">Descargar reportes de pago</h3>
                <div className="row">
                  <div className="col-md-6">
                  <Card style={{ width: '18rem' }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body >
                      <Card.Title>Detalle simcards</Card.Title>
                      <Card.Text>
                        En este reporte se muestra el detalle de las simcards que comisionaron en este pago
                      </Card.Text>
                      <Button variant="primary" onClick={
                        ()=>{
                        
                        DownladExcel()
                        }
                        }>Descargar reporte</Button>
                    </Card.Body>
                  </Card>
                  </div>
                  <div className="col-md-6">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

</div>

  );
}
