import React, {  ChangeEvent, useState, useEffect } from "react";
import _, { isEmpty} from "lodash";
import Alert from "../Utilities/Alert";
import { getDealerbyId, editDealer } from "../../store/actions/dealers.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utilities/Loading";
import { IStateType } from "../../store/models/root.interface";
import { setHideError } from "../../store/actions/errorActions";
import { useHistory} from "react-router-dom";
/* import { STORAGE } from "../../common/types/Endpoints";
import { INews } from "../../store/models/news.interface"; */
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import {

  getProvinces,
  getDistritos,
  getCorregimientos,
} from "../../utils/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const initialstate = {
  canal: "Mixto",
  canal_id: 1,
  categoria: "",
  codigovendedor: "",
  coordinador: "",
  corregimineto_id: 1,
  corregimiento: "",
  created_at: new Date(),
  distrito: "",
  distrito_id: 1,
  encargado: "",
  id: 27,
  latitud: "",
  longitud: "",
  nombre: "",
  provincia: "",
  provincia_id: 1,
  qr: "",
  ruc: "",
  ruta: "",
  state_id: 1,
  telefono: "",
  tipocomercio_id: 1,
  updated_at: "",
};

export default function EditDealers() {
  const [state, setstate] = useState(initialstate);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);
  const [provincelist, setprovincelist] = useState([]);
  const [distritolist, setdistritolist] = useState([]);
  const [corregimientolist, setcorregimientolist] = useState([]);

  const user: string = useSelector((state: IStateType) => state.account.user);
  const dealer: any = useSelector((state: IStateType) => state.dealers.dealer);

  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });

  const dispatch = useDispatch();
  const error = useSelector((state: IStateType) => state.error.error);
 /*  const currentnew = useSelector((state: IStateType) => state.news.currentnew); */
  const history = useHistory();

  useEffect(() => {
    if (!error) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Ha ocurrido un error en el proceso de registro",
        type: "danger",
      });
      setshowalert(true);
    }
  }, [error]);

  useEffect(() => {
    dispatch(setHideError());

    const id = localStorage.getItem("editdealers");
    const jsondealer = JSON.parse(id);
    console.log(jsondealer.id);
    dispatch(getDealerbyId(_.get(user, "guid", ""), jsondealer.id));
    setstate(jsondealer);
    (async () => {
      setprovincelist(await getProvinces());
      setdistritolist(await getDistritos());
      setcorregimientolist(await getCorregimientos());
    })();
  }, []);

  useEffect(() => {
    if (dealer) {
      console.log("objeto dealer");
      console.log(dealer);
      setstate({ ...dealer, corregimiento: "" });
    }
  }, [dealer]);

  function save() {
    const {
      codigovendedor,
      nombre,
      ruc,
      qr,
      state_id,
      ruta,
      provincia_id,
      distrito_id,
      corregimineto_id,
      encargado,
      telefono,
      canal_id,
      tipocomercio_id,
      categoria,
      latitud,
      longitud,
      id,
    } = state;

    if (isEmpty(nombre)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo título",
        type: "warning",
      });
      setshowalert(true);
    } else {
      setvisible(true);

      const params = {
        guid: _.get(user, "guid", ""),
        codigovendedor,
        nombre,
        ruc,
        qr,
        state_id,
        ruta,
        provincia_id,
        distrito_id,
        corregimineto_id,
        encargado,
        telefono,
        canal_id,
        tipocomercio_id,
        categoria,
        latitud,
        longitud,
        id,
      };
      dispatch(editDealer(params));
      setmensaje({
        ...mensaje,
        title: "Actualización exitosa",
        description: "Noticia Actualizada Exitosamente",
        type: "success",
      });

      setshowalert(true);
      setvisible(false);
      //setstate(initialstate);
    }
  }

  function closeDialog() {
    console.log("holispolis");
    history.push("/dealers");
  }

  function onValueChanged(e: ChangeEvent<HTMLInputElement>): void {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  }

  const ProvinceDropDown = () => {
    return (
      <DropdownButton id="dropdown-basic-button" title={state.provincia}>
        {provincelist &&
          provincelist.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({
                  ...state,
                  provincia_id: Number(eventkey),
                  provincia: item.provincia,
                });
              }}
            >
              {item.provincia}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  const DistritoDropDown = () => {
    return (
      <DropdownButton id="dropdown-basic-button" title={state.distrito}>
        {distritolist &&
          distritolist.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({
                  ...state,
                  distrito_id: Number(eventkey),
                  distrito: item.distrito,
                });
              }}
            >
              {item.distrito}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  const CorregimientosDropDown = () => {
    return (
      <DropdownButton
        id="dropdown-basic-button"
        title={state.corregimiento}
        drop="right"
      >
        {corregimientolist &&
          corregimientolist.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({
                  ...state,
                  corregimineto_id: Number(eventkey),
                  corregimiento: item.corregimiento,
                });
              }}
            >
              {item.corregimiento}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Form>
        <h3>EDITAR DEALER {_.get(dealer, "codigovendedor", "")}</h3>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                onChange={onValueChanged}
                value={state.nombre}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>RUC</Form.Label>
              <Form.Control
                type="text"
                placeholder="RUC"
                name="ruc"
                onChange={onValueChanged}
                value={state.ruc}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>QR</Form.Label>
              <Form.Control
                type="text"
                placeholder="QR"
                name="qr"
                onChange={onValueChanged}
                value={state.qr}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Ruta</Form.Label>
              <Form.Control
                type="text"
                placeholder="ruta"
                name="ruta"
                onChange={onValueChanged}
                value={state.ruta}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Encargado</Form.Label>
              <Form.Control
                type="text"
                placeholder="Encargado"
                name="encargado"
                onChange={onValueChanged}
                value={state.encargado}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono"
                name="telefono"
                onChange={onValueChanged}
                value={state.telefono}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Canal</Form.Label>
              <Form.Control
                type="text"
                placeholder="Canal"
                name="canal"
                onChange={onValueChanged}
                value={state.canal}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Coordinador</Form.Label>
              <Form.Control
                type="text"
                placeholder="Coordinador"
                name="coordinador"
                onChange={onValueChanged}
                value={state.coordinador}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Provincia</Form.Label>

            <ProvinceDropDown />
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Distrito</Form.Label>
              <DistritoDropDown />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Corregimiento</Form.Label>
              <CorregimientosDropDown />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formBasicEmail">
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Activo/Inactivo"
              checked={state.state_id === 1 ? true : false}
              onChange={() => {
                setstate({
                  ...state,
                  state_id: state.state_id === 1 ? 2 : 1,
                });
              }}
            />
          </Form.Group>
        </Form.Group>

        <Button variant="primary" onClick={save}>
          Editar Dealer
        </Button>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </Form>
    </div>
  );
}
