import React, { FormEvent, ChangeEvent, useState, useEffect } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import _, { head, isEmpty, size } from "lodash";
import Alert from "../Utilities/Alert";
import {
  addNews,
  blanknew,
  editNewsActions,
} from "../../store/actions/news.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utilities/Loading";
import {
  getcoordinadores,
  getDealerList,
  getProvinces,
} from "../../utils/utils";
import { IStateType } from "../../store/models/root.interface";
import { setHideError } from "../../store/actions/errorActions";
import { addUser, cleanUser,destroyregistro } from "../../store/actions/users.action";
import { useHistory, useParams } from "react-router-dom";
import { STORAGE } from "../../common/types/Endpoints";
import { INews } from "../../store/models/news.interface";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Select from "react-select";

const initialstate = {
  id: 0,
  name: "",
  guid: "",
  email: "",
  indentificacion_id: 1,
  numero_identificacion: "",
  fecha_nacimiento: new Date(),
  telefono: "",
  provincia_id: 0,
  dealer_id: 0,
  coordinadores_id: 1,
  coordinadores: "1",
  operador_id: 0,
  withcard: "",
  num_card: "",
  estado: "",
  estado_id: 1,
  state_id: 1,
  tipo_user: "",
  tipo_user_description: "",
  latitud: "",
  longitud: "",
  avatar: {},
  created_at: new Date(),
  updated_at: new Date(),
  provincia: "",
  dealer: "",
  operador: "",
};

export default function AddUsers() {
  const [state, setstate] = useState(initialstate);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);
  const [coordinadoreslist, setcoordinadoreslist] = useState([]);
  const [provincelist, setprovincelist] = useState([]);
  const [dealerlist, setdealerlist] = useState([]);
  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });

  const dispatch = useDispatch();
  const detalle = useSelector((state: IStateType) => state.error.mensajeerror);
  const error = useSelector((state: IStateType) => state.error.error);
  const registeruser = useSelector(
    (state: IStateType) => state.users.registeruser
  );
  const history = useHistory();
  const user: string = useSelector((state: IStateType) => state.account.user);
  const userstore: string = useSelector(
    (state: IStateType) => state.users.user
  );

  

useEffect(() => {
  //console.log('detalle de errores', JSON.stringify(detalle));
  if (error === 'error' && detalle) {
    // Asegura que 'detalle' es un objeto con propiedades que son arrays de strings
    const errores = Object.entries(detalle as Record<string, string[]>).reduce((acc, [key, value]) => {
      // Verifica si el valor es realmente un array antes de intentar usar 'join'
      if (Array.isArray(value)) {
        return acc + ` - ${key}: ${value.join(', ')}\n`;
      }
      return acc;
    }, '');

    setmensaje({
      ...mensaje,
      title: "Validación de datos",
      description: `Ha ocurrido un error en el proceso de registro:  ${errores}`,
      type: "danger",
    });
    setshowalert(true);
    setvisible(false);
    dispatch(setHideError());
  }
  else{
    setvisible(false);
    //dispatch(setHideError());
  }
}, [error, detalle]); // Incluye 'detalle' en las dependencias si es relevante para la actualización


  useEffect(() => {
    dispatch(setHideError());
    dispatch(blanknew());
    dispatch(cleanUser());

    (async () => {
      setcoordinadoreslist(await getcoordinadores(1));
      setdealerlist(await getDealerList(_.get(user, "guid", "")));
      setprovincelist(await getProvinces());
    })();
  }, []);


  useEffect(() => {
    (async () => {
      setcoordinadoreslist(await getcoordinadores(state.provincia_id));
    })();
  }, [state.provincia_id]);

  useEffect(() => {
   
    if (registeruser) {
      
      setmensaje({
        ...mensaje,
        title: "Creacion exitosa",
        description: "Usuario creado Exitosamente",
        type: "success",
      });
      setshowalert(true);
      setvisible(false);
      dispatch(setHideError());
      dispatch(blanknew());
      dispatch(cleanUser());
      dispatch(destroyregistro());
    }
  }, [registeruser]);

  const ProvinceDropDown = () => {
    return (
      <DropdownButton id="dropdown-basic-button" title={state.provincia}>
        {provincelist &&
          provincelist.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({
                  ...state,
                  provincia_id: Number(eventkey),
                  provincia: item.provincia,
                });
              }}
            >
              {item.provincia}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  const DealerDropDown = () => {
    let dealeroption: any = [];
    dealerlist.map((item) =>
      dealeroption.push({ label: item.nombre, value: item.id })
    );

    return (
      <Select
        options={dealeroption}
        onChange={(opt: any) => {
          setstate({
            ...state,
            dealer_id: Number(opt.value),
            dealer: opt.label,
          });
        }}
        defaultValue={{ label: state.dealer, value: state.dealer_id }}
      />
    );
  };

  const CoordinadoresDropdown = () => {
    let titulocoordinador = "";

   
    if (coordinadoreslist) {
      coordinadoreslist.map((item) => {
        if (Number(item.id) === Number(state.coordinadores)) {
          titulocoordinador = item.nombre;
        }
      });
    }

    return (
      <DropdownButton
        id="dropdown-basic-button"
        title={isEmpty(titulocoordinador) ? "Seleccione..." : titulocoordinador}
      >
        {coordinadoreslist &&
          coordinadoreslist.map((item) => (
            <Dropdown.Item
              key={item.id.toString()}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({ ...state, coordinadores: eventkey });
              }}
            >
              {item.nombre}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  const validaremail = (text: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      return false;
    } else {
      return true;
    }
  };

  function save() {
    const {
      id,
      name,
      guid,
      email,
      indentificacion_id,
      numero_identificacion,
      fecha_nacimiento,
      telefono,
      provincia_id,
      dealer_id,
      coordinadores_id,
      coordinadores,
      operador_id,
      withcard,
      num_card,
      state_id,
      tipo_user,
      latitud,
      longitud,
      avatar,
      created_at,
      updated_at,
      provincia,
      dealer,
    } = state;

    if (isEmpty(name)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo nombre",
        type: "warning",
      });
      setshowalert(true);
    } else if (isEmpty(email) && !validaremail(email)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo correo",
        type: "warning",
      });
      setshowalert(true);
    } else if (isEmpty(numero_identificacion)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description:
          "Favor ingrese el valor para el campo número de identificación",
        type: "warning",
      });
      setshowalert(true);
    } else if (isEmpty(fecha_nacimiento)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese su fecha de nacimiento",
        type: "warning",
      });
      setshowalert(true);
    } else {

      const params = {
        name: _.trim(state.name),
        password: "12345678",
        indentificacion_id: state.indentificacion_id,
        provincia_id: state.provincia_id,
        identificacion: _.trim(numero_identificacion),
        fecha_nacimiento: new Date(fecha_nacimiento),
        telefono: _.trim(telefono),
        dealer_id: dealer_id, //state.dealer
        coordinadores_id: coordinadores, //state.coordinador
        operador_id: operador_id, //state.operator
        latitud: 0,
        longitud: 0,
        avatar: "",
        email: _.trim(email),
      };



      dispatch(addUser(params));

      setvisible(true);
    }
  }

  function closeDialog() {
    history.push("/users");
  }

  function onValueChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value, type } = e.target;
    //console.log(name, value);
  
    // Actualiza el estado solo si el valor es válido para el tipo de input específico
    if (type === 'date') {
      // Para inputs de fecha, verifica que coincida con el formato YYYY-MM-DD
      if (!value || value.match(/^\d{4}-\d{2}-\d{2}$/)) {
        setstate({ ...state, [name]: value });
      }
    } else {
      // Para todos los otros tipos de inputs, actualiza el estado normalmente
      setstate({ ...state, [name]: value });
    }
  }
  
  
  function formatDate(dateString: string): string {
    // Asegúrate de que dateString sea una fecha válida y esté en formato YYYY-MM-DD
    // Si no lo está, retorna una cadena vacía o maneja como veas necesario
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(dateString)) {
      return dateString;
    } else {
      return '';
    }
  }
  


  function fileSelectedHandler(e: ChangeEvent<HTMLInputElement>) {
    let file = e.target.files;
    
    setstate({ ...state, avatar: file[0] });
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Form>
        <h3>AGREGAR USUARIO</h3>
        <br />
        <Row></Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="name"
                onChange={onValueChanged}
                value={state.name}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo"
                name="email"
                onChange={onValueChanged}
                value={state.email}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Tipo de Identificación</Form.Label>

              <DropdownButton
                id="dropdown-basic-button"
                title={
                  Number(state.indentificacion_id) === 1
                    ? "Cédula"
                    : "Pasaporte"
                }
              >
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      indentificacion_id: Number(eventkey),
                    });
                  }}
                >
                  Cédula
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      indentificacion_id: Number(eventkey),
                    });
                  }}
                >
                  Pasaporte
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Número de Identificación</Form.Label>
              <Form.Control
                type="text"
                placeholder="Número de Identificación"
                name="numero_identificacion"
                onChange={onValueChanged}
                value={state.numero_identificacion}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Provincia</Form.Label>
              <ProvinceDropDown />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Dealer (coloque nombre para buscar)</Form.Label>
              <DealerDropDown />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Coordinadores</Form.Label>
              <CoordinadoresDropdown />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Operador</Form.Label>
              <DropdownButton id="dropdown-basic-button" title={state.operador}>
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Más Móvil",
                    });
                  }}
                >
                  Más Móvil
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Claro",
                    });
                  }}
                >
                  Claro
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Digicel",
                    });
                  }}
                >
                  Digicel
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Tigo",
                    });
                  }}
                >
                  Tigo
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control
                  type="date"
                  placeholder="aaaa-mm-dd"
                  name="fecha_nacimiento"
                  onChange={onValueChanged}
                  value={state.fecha_nacimiento ? formatDate(state.fecha_nacimiento.toString()) : ''}
                />
              {size(state.fecha_nacimiento) > 0 && (
                <span>Fecha seleccionada {state.fecha_nacimiento}</span>
              )}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono"
                name="telefono"
                onChange={onValueChanged}
                value={state.telefono}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Activo/Inactivo"
                  checked={state.estado_id === 1 ? true : false}
                  onChange={() => {
                    setstate({
                      ...state,
                      state_id: state.estado_id === 1 ? 2 : 1,
                      estado_id: state.estado_id === 1 ? 2 : 1,
                    });
                  }}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Tipo de Usuario</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={state.tipo_user === "1" ? "Usuario" : "Editor"}
              >
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      tipo_user: eventkey,
                      tipo_user_description: "Usuario",
                    });
                  }}
                >
                  Usuario
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      tipo_user: eventkey,
                      tipo_user_description: "Editor",
                    });
                  }}
                >
                  Editor
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" onClick={save}>
          Agregar Usuario
        </Button>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </Form>
    </div>
  );
}
