import { ICommission, CommissionModificationStatus } from "../models/commission.interface";
import { SET_ERROR, setError } from "./errorActions";
import { ENDPOINTS } from "../../common/types/Endpoints";
import { postData, postDataExcel } from "../../utils/utils";
import _, { isEmpty, size } from "lodash";

export const ADD_COMMISSION: string = "ADD_COMMISSION";
export const EDIT_COMMISSION: string = "EDIT_COMMISSION";
export const REMOVE_COMMISSION: string = "REMOVE_COMMISSION";
export const CHANGE_COMMISSION_COMISION: string = "CHANGE_COMMISSION_COMISION";
export const CHANGE_COMMISSION_PENDING_EDIT: string = "CHANGE_COMMISSION_PENDING_EDIT";
export const CLEAR_COMMISSION_PENDING_EDIT: string = "CLEAR_COMMISSION_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const UPDATE_COMISSIONS: string = "UPDATE_COMISSIONS"; 

export function addCommission(commission: ICommission): IAddCommissionActionType {
    return { type: ADD_COMMISSION, commission: commission };
}

export function uploadComissions(params: any){

    
    return (dispatch:any) => 
    {
        let formdata = new FormData();
      // console.log(params); 
        formdata.append("comision",params.commissions);
        formdata.append("guid", params.guid);
       // console.log(JSON.stringify(formdata)); 
        const resultado =  fetch(ENDPOINTS.UPLOADCOMISSIONS, {
            method: "post",
            body: formdata,
          })
            .then(async (response) => {
              /*await response.json().then(async (data) => {
                  console.log(data); 
                const state = _.get(data, "status", "error"); 
               if(state ==="success")
               {
                const comision = _.get(data, "message.pagos", {});
                console.log(comision); 
             //  dispatch({ type: UPDATE_COMISSIONS, uploadfile: true });
               }
               else {
            //    dispatch({ type: UPDATE_COMISSIONS, uploadfile: false });
                dispatch({ type: SET_ERROR, error: data })
               }
              });
            })
            .catch((err) => {
                console.log(err); 
                dispatch({ type: SET_ERROR, error: err })
            });¨()*/
           // console.log(response);
        })
    }
}

export function editCommission(commission: ICommission): IEditCommissionActionType {
    return { type: EDIT_COMMISSION, commission: commission };
}

export function removeCommission(id: number): IRemoveCommissionActionType {
    return { type: REMOVE_COMMISSION, id: id };
}

export function changeCommissionComision(id: number, comision: number): IChangeCommissionComisionType {
    return { type: CHANGE_COMMISSION_COMISION, id: id, comision: comision };
}

export function changeSelectedCommission(commission: ICommission): IChangeSelectedCommissionActionType {
    return { type: CHANGE_COMMISSION_PENDING_EDIT, commission: commission };
}

export function clearSelectedCommission(): IClearSelectedCommissionActionType {
    return { type: CLEAR_COMMISSION_PENDING_EDIT };
}

export function setModificationState(value: CommissionModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

interface IAddCommissionActionType { type: string, commission: ICommission };
interface IEditCommissionActionType { type: string, commission: ICommission };
interface IRemoveCommissionActionType { type: string, id: number };
interface IChangeSelectedCommissionActionType { type: string, commission: ICommission };
interface IClearSelectedCommissionActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  CommissionModificationStatus};
interface IChangeCommissionComisionType {type: string, id: number, comision: number};
