import React, { FormEvent, ChangeEvent, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import _, { head, isEmpty, size } from "lodash";
import Alert from "../Utilities/Alert";
import {
  addNews,
  blanknew,
  editNewsActions,
} from "../../store/actions/news.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utilities/Loading";
import { IStateType } from "../../store/models/root.interface";
import { setHideError } from "../../store/actions/errorActions";
import { useHistory, useParams } from "react-router-dom";
import { STORAGE } from "../../common/types/Endpoints";
import { INews } from "../../store/models/news.interface";
const initialstate = {
  titulo: "",
  descripcion: "",
  banner: {},
  url: "",
  like: "",
  state_id: 1,
  created_at: new Date(),
  updated_at: new Date(),
  id: "",
};

export default function EditNews() {
  const [state, setstate] = useState(initialstate);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);

  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });
  const dispatch = useDispatch();
  const error = useSelector((state: IStateType) => state.error.error);
  const currentnew = useSelector((state: IStateType) => state.news.currentnew);
  const user: string = useSelector((state: IStateType) => state.account.user);
  const history = useHistory();
  console.log("ESTADO");
  console.log(state);

  useEffect(() => {
    if (!error) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Ha ocurrido un error en el proceso de registro",
        type: "danger",
      });
      setshowalert(true);
    }
  }, [error]);

  useEffect(() => {
    dispatch(setHideError());
    dispatch(blanknew());
    const id = localStorage.getItem("editnews");
    console.log(id);
    setstate(JSON.parse(id));
  }, []);

  function save() {
    const { titulo, descripcion, banner, url, id } = state;
    console.log("HOLA BANNER");
    console.log(banner);
    console.log(size(banner));
    if (isEmpty(titulo)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo título",
        type: "warning",
      });
      setshowalert(true);
    } else if (isEmpty(descripcion)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo descripción",
        type: "warning",
      });
      setshowalert(true);
    } else {
      setvisible(true);
      dispatch(editNewsActions(_.get(user, "guid", ""), state));
      setmensaje({
        ...mensaje,
        title: "Actualización exitosa",
        description: "Noticia Actualizada Exitosamente",
        type: "success",
      });
      setshowalert(true);
      setvisible(false);
      //setstate(initialstate);
    }
  }

  function closeDialog() {
    history.push("/news");
  }

  function fileSelectedHandler(e: ChangeEvent<HTMLInputElement>) {
    let file = e.target.files;
    console.log(file[0]);
    setstate({ ...state, banner: file[0] });
  }

  function onValueChanged(e: ChangeEvent<HTMLInputElement>): void {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Form>
        <h3>EDITAR NOTICIA</h3>
        <br />
        Subir Imagen<br></br>
        <input
          type="file"
          onChange={fileSelectedHandler}
          accept="image/png, image/jpeg"
        />
        <br />
        <br />
        {state.banner && (
          <img
            src={`${STORAGE}${state.banner}`}
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />
        )}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            placeholder="Título"
            name="titulo"
            onChange={onValueChanged}
            value={state.titulo}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            type="text"
            placeholder="Descripción"
            name="descripcion"
            onChange={onValueChanged}
            value={state.descripcion}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Url</Form.Label>
          <Form.Control
            type="text"
            placeholder="url"
            name="url"
            onChange={onValueChanged}
            value={state.url}
          />
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Activo/Desactivo"
            checked={Number(state.state_id) === 1 ? true : false}
            onChange={() => {
              setstate({
                ...state,
                state_id: Number(state.state_id) === 1 ? 2 : 1,
              });
            }}
          />
        </Form.Group>
        <Button variant="primary" onClick={save}>
          Editar Noticia
        </Button>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </Form>
    </div>
  );
}
