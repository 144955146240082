import React, { Fragment, Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import TopCard from "../../common/components/TopCard";
import GoogleMapReact from "google-map-react";
import { IStateType, IUserState } from "../../store/models/root.interface";
import _, { map } from "lodash";
import Loading from "../Utilities/Loading";
import { listusers } from "../../store/actions/users.action";
import Point from "./Point";
import { IUser } from "../../store/models/user.interface";

const Map: React.FC = () => {
  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };
  const dispatch: Dispatch<any> = useDispatch();
  dispatch(updateCurrentPath("map", ""));
  const user: string = useSelector((state: IStateType) => state.account.user);
  const users: IUserState = useSelector((state: IStateType) => state.users);
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    setvisible(true);
    dispatch(listusers(_.get(user, "guid", "")));
    setvisible(false);
  }, []);

  useEffect(() => {
    if (users.users) {
      if (visible) {
        setvisible(false);
      }
    }
  }, [users.users]);

  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Usuarios por ubicación</h1>

      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4"></div>
      </div>

      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC7kiOyzaI7vM1G8TD6YK6VfG567xkmvYU" }}
          defaultCenter={{
            lat: 9.01,
            lng: -79.51,
          }}
          defaultZoom={9}
        >
          {users.users &&
            map(users.users, (item: IUser) => (
              <Point lat={Number(item.latitud)} lng={Number(item.longitud)} />
            ))}
        </GoogleMapReact>
      </div>
      <Loading visible={visible} setvisible={setvisible} />
    </Fragment>
  );
};

export default Map;
