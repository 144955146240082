import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AlertDismissible(props: any) {
  const { title, description, visible, setVisible, type, closeDialog } = props;

  const hide = () => {
    setVisible(false);
    console.log(type);
    if (type == "success") {
      closeDialog();
    }
  };

  return (
    <Modal
      show={visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Alert
        show={visible}
        variant={type}
        style={{ marginBottom: 0, paddingBottom: 20 }}
      >
        <Alert.Heading>{title}</Alert.Heading>
        <p>{description}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={hide} variant={type}>
            Aceptar
          </Button>
        </div>
      </Alert>
    </Modal>
  );
}
