import { Bar } from "@reactchartjs/react-chart.js";

/*const data = {
  labels: ["Ene", "Feb", "Mar", "Abr", "May"],
  datasets: [
    {
      label: "Sim Cards Escaneados",
      data: [12, 13, 13, 10, 12, 13],
      backgroundColor: "#ff4713",
    },
    {
      label: "Comisiones",
      data: [20, 19, 22, 18, 19, 18],
      backgroundColor: "#981d97",
    },
  ],
};*/

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const GroupedBar = (data) => (
  <>
    <div class="chart-container">
      <Bar data={data.data} options={options} height={50} />
    </div>
  </>
);

export default GroupedBar;
