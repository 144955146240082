import { ENDPOINTS } from "../common/types/Endpoints.ts";
import _ from "lodash";

export async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function putData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  console.log(response);
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function postDataExcel(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.blob(); // parses JSON response into native JavaScript objects
}

export async function postDataForm(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, *cors, same-origin
    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
    },
    // redirect: "follow", // manual, *follow, error
    //referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const getcoordinadores = async (provincia) => {
  let data = [];
  await getData(`${ENDPOINTS.GET_COORDINADOR_LIST}${provincia}}`, {})
    .then((resp) => {
      const state = _.get(resp, "status", "error");
/*       console.log("Estado coordinadores");
      console.log(state);
      console.log("Respuesta Coordinadores");
      console.log(resp); */

      if (state === "success") {
        let resultado = _.get(resp, "message.coordinadores", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return data;
};

export const getDealers = async (provincia) => {
  let data = [];
  await getData(`${ENDPOINTS.GET_COORDINADOR_LIST}${provincia}}`, {})
    .then((resp) => {
      const state = _.get(resp, "status", "error");
      console.log("Estado coordinadores");
      console.log(state);
      console.log("Respuesta Coordinadores");
      console.log(resp);

      if (state === "success") {
        let resultado = _.get(resp, "message.coordinadores", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return data;
};

export const getDealerList = async (guid) => {
  let data = [];

  await postData(ENDPOINTS.GETDEALERS, { guid: guid })
    .then((resp) => {
      const state = _.get(resp, "status", "error");
      if (state === "success") {
        let resultado = _.get(resp, "message.dealers", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return data;
};

export const getProvinces = async () => {
  let data = [];

  await getData(ENDPOINTS.GET_PROVINCES, {})
    .then((resp) => {
      const state = _.get(resp, "status", "error");

      if (state === "success") {
        let resultado = _.get(resp, "message.provincias", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return data;
};

export const getDistritos = async () => {
  let data = [];

  await getData(ENDPOINTS.GETDISTRITOS, {})
    .then((resp) => {
      const state = _.get(resp, "status", "error");

      if (state === "success") {
        let resultado = _.get(resp, "message.distritos", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return data;
};

export const getCorregimientos = async () => {
  let data = [];

  await getData(ENDPOINTS.GETCORREGIMIENTOS, {})
    .then((resp) => {
      const state = _.get(resp, "status", "error");
      console.log(resp);
      if (state === "success") {
        let resultado = _.get(resp, "message.corregimientos", []);
        data = resultado.slice();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return data;
};
