export interface IDealer {
    id: number,
    codigovendedor: number,
    nombre: string,
    ruc: string,
    qr: string,
    state_id: number,
    ruta: string,
    provincia_id: number,
    distrito_id: number,
    corregimineto_id: number,
    encargado: string,
    telefono: string,
    canal_id: number,
    tipocomercio_id: number,
    categoria: string,
    latitud: string,
    longitud: string,
    created_at: Date,
    updated_at: Date,
}

export interface IDealerList {
    canal:string, 
    codigovendedor: string, 
    coordinador: string, 
    distrito: string, 
    id: number, 
    nombre: string, 
    provincia: string,
}

export enum DealerModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}
