

export interface IPayment {
    id: number;
    codigo_pago: string; 
    correspondiente_a: Date,
    cantidad_simcards: number,
    cantidad_usuarios: number,
    monto: number,
    state_id: number,
    create_by: number,
    created_at: Date,
    updated_at: Date,
    creado_por: string,
    estado: string; 
}

export enum PaymentModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}
