import React, { Fragment, Dispatch, useState, useEffect } from "react";
import DealerList from "./DealersList";
import DealerForm from "./DealersForm";
import TopCard from "../../common/components/TopCard";
import "./Dealers.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IDealerState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import Popup from "reactjs-popup";
import {
  removeDealer,
  clearSelectedDealer,
  setModificationState,
  changeSelectedDealer,
  listDealers,
  ExportExcel,
} from "../../store/actions/dealers.action";
import {
  DealerModificationStatus,
  IDealer,
} from "../../store/models/dealer.interface";
import _ from "lodash";
import Loading from "../Utilities/Loading";

const Dealers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const dealers: IDealerState = useSelector(
    (state: IStateType) => state.dealers
  );
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const user: string = useSelector((state: IStateType) => state.account.user);
  const [visible, setvisible] = useState(false);

  //const numberItemsCount: number = dealers.dealers.length;
  //const totalPrice: number = dealers.dealers.reduce((prev, next) => prev + (next.comision || 0), 0);
  //const totalAmount: number = dealers.dealers.reduce((prev, next) => prev + (next.comision || 0), 0);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    dispatch(clearSelectedDealer());
    dispatch(updateCurrentPath("dealers", "list"));
  }, [path.area, dispatch]);

  useEffect(() => {
    dispatch(listDealers(_.get(user, "guid", "")));
  }, []);

  useEffect(() => {
    if (dealers.dealers) {
      if (visible) {
        setvisible(false);
      }
    }
  }, [dealers.dealers]);

  function DownladExcel() {
    console.log("holis");
    dispatch(ExportExcel(_.get(user, "guid", "")));
  }

  function onDealerSelect(dealer: IDealer): void {
    dispatch(changeSelectedDealer(dealer));
    dispatch(setModificationState(DealerModificationStatus.None));
  }

  function onDealerRemove() {
    if (dealers.selectedDealer) {
      setPopup(true);
    }
  }

  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Dealers</h1>

      {/* <div className="row">
        <TopCard title="USUARIO COUNT" text={`${numberItemsCount}`} icon="box" class="primary" />
        <TopCard title="USUARIO AMOUNT" text={`${totalAmount}`} icon="warehouse" class="danger" />
        <TopCard title="SUMMARY PRICE" text={`$${totalPrice}`} icon="dollar-sign" class="success" />
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4">
          <button
            className="btn btn-lg top-button mr-2 float-right"
            onClick={DownladExcel}
          >
            <i className="fas fa-file-excel icon-green"></i> Descargar Excel
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Listado</h6>
              <div className="header-buttons"></div>
            </div>
            <div className="card-body">
              <DealerList onSelect={onDealerSelect} />
            </div>
          </div>
        </div>
        {dealers.modificationState === DealerModificationStatus.Create ||
        (dealers.modificationState === DealerModificationStatus.Edit &&
          dealers.selectedDealer) ? (
          <DealerForm />
        ) : null}
      </div>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Are you sure?</div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!dealers.selectedDealer) {
                  return;
                }
                dispatch(removeDealer(dealers.selectedDealer.id));
                dispatch(clearSelectedDealer());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
      <Loading visible={visible} setvisible={setvisible} />
    </Fragment>
  );
};

export default Dealers;
