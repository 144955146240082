
import { ENDPOINTS } from "../../common/types/Endpoints";
import Login from "../../components/Account/Login";
import {postData} from "../../utils/utils"; 
import _ from "lodash"; 
import {SET_ERROR , setError} from "./errorActions"; 


export function login(email: string, password: string): any {
    

    return (dispatch:any) => 
    {
      /// console.log(email); 
      // console.log(password); 
        postData(ENDPOINTS.LOGIN,{email: email, password: password} ).then(resp=>{
            const state = _.get(resp, "state", "error"); 
          //  console.log("ESTADO"); 
           // console.log(state);
            if(state==="success")
            {
            dispatch({type: LOG_IN, user: _.get(resp, "message.user",{})})
            } else { 
          //  console.log("entro acá"); 
           // console.log(resp); 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch(err=>{ dispatch(setError(err))})

    }
    

    //return { type: LOG_IN, email: email };
}

export function logout(): ILogOutActionType {
    return { type: LOG_OUT};
}

export const LOG_IN: string = "LOG_IN";
export const LOG_OUT: string = "LOG_OUT";


interface ILogInActionType { type: string, email: string };
interface ILogOutActionType { type: string };
