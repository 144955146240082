import React from "react";
/* import { IStateType, IDealerState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { IDealer, DealerModificationStatus } from "../../store/models/dealer.interface";
import TextInput from "../../common/components/TextInput";
import { editDealer, clearSelectedDealer, setModificationState, addDealer } from "../../store/actions/dealers.action";
import { addNotification } from "../../store/actions/notifications.action";
import NumberInput from "../../common/components/NumberInput";
import Checkbox from "../../common/components/Checkbox";
import SelectInput from "../../common/components/Select";
import { OnChangeModel, IDealerFormState } from "../../common/types/Form.types"; */

const DealerForm: React.FC = () => {
  /*const dispatch: Dispatch<any> = useDispatch();
  const dealers: IDealerState | null = useSelector((state: IStateType) => state.dealers);
  let dealer: IDealer | null = dealers.selectedDealer;
  const isCreate: boolean = (dealers.modificationState === DealerModificationStatus.Create);
  
  if (!dealer || isCreate) {
    dealer = { id: 0, name: "", update_date: "", lead: "", state: "", local_name: ""};
  }

  const [formState, setFormState] = useState({
    name: { error: "", value: dealer.name },
    local_name: { error: "", value: dealer.local_name },
    state: { error: "", value: dealer.state },
    lead: { error: "", value: dealer.lead },
    update_date: { error: "", value: dealer.update_date },
  });

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
  }

  function saveUser(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (isFormInvalid()) {
      return;
    }

    let saveUserFn: Function = (isCreate) ? addDealer : editDealer;
    saveForm(formState, saveUserFn);
  }

  function saveForm(formState: IDealerFormState, saveFn: Function): void {
    if (dealer) {
      dispatch(saveFn({
        ...dealer,
        name: formState.name.value,
        local_name: formState.local_name.value,
        state: formState.state.value,
        lead: formState.lead.value,
        update_date: formState.update_date.value,
      }));

      dispatch(addNotification("Dealer editado", `Dealer ${formState.name.value} editado por ti`));
      dispatch(clearSelectedDealer());
      dispatch(setModificationState(DealerModificationStatus.None));
    }
  }

  function cancelForm(): void {
    dispatch(setModificationState(DealerModificationStatus.None));
  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function isFormInvalid(): boolean {
    return (formState.lead.error || formState.update_date.error
      || formState.name.error || formState.state.error
      || formState.local_name.error || !formState.name.value) as boolean;
}

  return (
    <Fragment>
      <div className="col-xl-7 col-lg-7">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">{(isCreate ? "Crear" : "Editar")} Dealer</h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveUser}>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.name.value}
                  field="name"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Nombre"
                  placeholder="Nombre" />
              </div>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.local_name.value}
                  field="local_name"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Nombre local"
                  placeholder="Nombre local" />
              </div>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.state.value}
                  field="state"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Provincia"
                  placeholder="Provincia" />
              </div>
              <div className="form-group">
                <TextInput id="input_amount"
                  field = "lead"
                  value={formState.lead.value}
                  onChange={hasFormValueChanged}
                  required={false}
                  maxLength={100}
                  label="Coordinador"
                  placeholder="Coordinador" />
              </div>
              <div className="form-group">
                <TextInput id="input_description"
                  field = "update_date"
                  value={formState.update_date.value}
                  onChange={hasFormValueChanged}
                  required={false}
                  maxLength={100}
                  label="Fecha actualización"
                  placeholder="Fecha actualización" />
              </div>
              <button className="btn btn-danger" onClick={() => cancelForm()}>Cancelar</button>
              <button type="submit" className={`btn btn-success left-margin ${getDisabledClass()}`}>Guardar</button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>*/

    return (
      <div>Holis</div>
  );
};

export default DealerForm;
