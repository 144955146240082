import React, { FormEvent , ChangeEvent, useState, useEffect} from "react";
import { Button, Form } from "react-bootstrap";
import _ , { isEmpty, size} from "lodash"; 
import Alert from "../Utilities/Alert"; 
import {addNews , blanknew } from "../../store/actions/news.action"; 
import {useDispatch, useSelector} from "react-redux"; 
import Loading from "../Utilities/Loading"; 
import { IStateType } from "../../store/models/root.interface";
import {setHideError } from "../../store/actions/errorActions"; 
import { useHistory } from "react-router-dom";

const initialstate={ 
  titulo: "", 
  descripcion:"", 
  banner: {}, 
  url:"", 
}


export default function AddNews() {

  const [state, setstate] = useState(initialstate); 
  const [showalert, setshowalert] = useState(false); 
  const [visible, setvisible] = useState(false); 

  const [mensaje, setmensaje] = useState({
    title:"", 
    description: "", 
    type:"",
  })
 const dispatch = useDispatch(); 
 const error = useSelector((state: IStateType) => state.error.error);
 const currentnew = useSelector((state: IStateType) => state.news.currentnew);
 const history = useHistory();

 useEffect(()=>{
   if(!error)
   {
    setmensaje({... mensaje, title: "Validación de datos", description: "Ha ocurrido un error en el proceso de registro", type: "danger"})
      setshowalert(true)

   }
  }, 
 [error])



useEffect(()=>{
  dispatch(setHideError())
  dispatch(blanknew())
}, [])
 

 
  function save ()
  {
    const { titulo,descripcion, banner,  url} = state; 
    console.log("HOLA BANNER"); 
    console.log(banner); 
    console.log(size(banner)); 
    if(isEmpty(titulo))
    {
      setmensaje({... mensaje, title: "Validación de datos", description: "Favor ingrese el valor para el campo título", type: "warning"})
      setshowalert(true)
    } else if (isEmpty(descripcion))
    {
      setmensaje({... mensaje, title: "Validación de datos", description: "Favor ingrese el valor para el campo descripción", type: "warning"})
      setshowalert(true)
    } 
    else if (isEmpty(_.get(banner, "name", "")))
    {
      setmensaje({... mensaje, title: "Validación de datos", description: "Favor suba una imagen que acompañe a la noticia.", type: "warning"})
      setshowalert(true)

    }
    else{ 
      setvisible(true);
      dispatch(addNews(state )); 


      setmensaje({... mensaje, title: "Registro exitoso", description: "Noticia Registrada Exitosamente", type: "success"})
      setshowalert(true)
    setvisible(false); 
    setstate(initialstate); 
    }
  }

  function closeDialog(){ 
    history.push("/news")
  }


  function fileSelectedHandler(e: ChangeEvent<HTMLInputElement>) {
    let file  = e.target.files;
    console.log(file[0]);
    setstate({...state, banner: file[0]})
  }


  function onValueChanged(e: ChangeEvent<HTMLInputElement>): void 
  {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state); 
  }




  return (
    <div style={{marginLeft: 20, marginRight: 20,}}>
    <Form>
      <h3>AGREGAR NOTICIA</h3>
      <br/>
      Subir Imagen<br></br>
      <input
        type="file"
        onChange={fileSelectedHandler}
        accept="image/png, image/jpeg"
      />
     <br/>
     <br/>
      <Form.Group controlId="formBasicEmail" >
        <Form.Label>Título</Form.Label>
        <Form.Control type="text" placeholder="Título"  name="titulo" onChange={onValueChanged}/>
      </Form.Group>
 
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Descripción</Form.Label>
        <Form.Control type="text" placeholder="Descripción" name="descripcion" onChange={onValueChanged}/>
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Url</Form.Label>
        <Form.Control type="text" placeholder="url" name="url" onChange={onValueChanged} />
      </Form.Group>
 
      <Button variant="primary" onClick={save} >
        Agregar Noticia
      </Button>
      <Alert title={mensaje.title} description={mensaje.description} visible = {showalert} setVisible={setshowalert} type={mensaje.type}  closeDialog={closeDialog}/>
      <Loading visible={visible} setvisible={setvisible} />

    </Form>
    </div> 
  );
}
