import { IUserState, IActionBase } from "../models/root.interface";
import { ADD_USER, 
    CHANGE_USER_PENDING_EDIT, 
    EDIT_USER, REMOVE_USER,
    CLEAR_USER_PENDING_EDIT, 
    SET_MODIFICATION_STATE, 
    CHANGE_USER_COMISION, 
    LIST_USERS, 
    CLEAN_USER,
    RESET_PASSWORD
} from "../actions/users.action";
import { IUser, UserModificationStatus } from "../models/user.interface";



const initialState: IUserState = {
    modificationState: UserModificationStatus.None,
    selectedUser: null,
    users: [],
    user: null,
    registeruser: false,
};

function usersReducer(state: IUserState = initialState, action: IActionBase): IUserState {
    switch (action.type) {
        case ADD_USER: {
        
            return { ...state, registeruser:  action.registeruser};
        }
        case EDIT_USER: {
        
            return { ...state, user: action.user };
        }
        case CLEAN_USER: {
        
            return { ...state, user: null};
        }
        case REMOVE_USER: {
            return { ...state, users: state.users.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_USER_PENDING_EDIT: {
            return { ...state, selectedUser: action.usuario };
        }
        case CLEAR_USER_PENDING_EDIT: {
            return { ...state, selectedUser: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case CHANGE_USER_COMISION: {
            // eslint-disable-next-line
            const foundIndex: number = state.users.findIndex(pr => pr.id === action.id);
            let users: IUser[] = state.users;
            return { ...state, users: users };
        }
        case LIST_USERS: {
            return {...state, users: action.users}
        }
        case RESET_PASSWORD: {
            return {...state,users:action.user}
        }
        default:
            return state;
    }
}


export default usersReducer;
