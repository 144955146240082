import { IActionBase } from "../models/root.interface";
import { IAccount } from "../models/account.interface";
import { LOAD_REPORT } from "../actions/reports.actions";

const initialState: any = {
    report: {},
 
};

function reportReducer(state: any = initialState, action: any): any {
    switch (action.type) {
        case LOAD_REPORT: {
            return { ...state, report: (action.report)};
        }
        default:
            return state;
    }
}


export default reportReducer;
