import { IUser, UserModificationStatus } from "../models/user.interface";
import {postData, getData, postDataExcel, putData} from "../../utils/utils"; 
import {SET_ERROR , setError} from "./errorActions"; 
import { ENDPOINTS } from "../../common/types/Endpoints";
import _ from "lodash"; 
export const ADD_USER: string = "ADD_USER";
export const EDIT_USER: string = "EDIT_USER";
export const REMOVE_USER: string = "REMOVE_USER";
export const CHANGE_USER_COMISION: string = "CHANGE_USER_COMISION";
export const CHANGE_USER_PENDING_EDIT: string = "CHANGE_USER_PENDING_EDIT";
export const CLEAR_USER_PENDING_EDIT: string = "CLEAR_USER_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const LIST_USERS: string = "LIST_USERS";
export const CLEAN_USER : string = "CLEAN_USER"; 
//JOHNNY EDICION

export const RESET_PASSWORD:string="RESET_PASSWORD"

export function addUser(params: any) {

    /* console.log("PARAMETROS QUE LLEGAN"); 
     console.log(params); */ 
    return (dispatch:any) => 
    {
        //console.log("URL"); 
        const url = `${ENDPOINTS.CREATEUSER}`
        //console.log('url para agrgar usuario',url);
        postData(url, params ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            //console.log("EDITAR_USER"); 
            //console.log(resp);
           
            if(state==="success")
            {
            dispatch({type: ADD_USER, registeruser:true})
            } else {
           
                dispatch(setError({ error: 'error', detalle: resp.message.detail }));

                //dispatch({ type: SET_ERROR, error: "error" })
            }
        })
        .catch((err)=>{ 
            if (err.status === 422) {
                console.error('error agregando usuario',err);  
            }
            dispatch(setError(err))})
    }
    
}

export function destroyregistro(){
    return (dispatch:any) => 
        {
            dispatch({type: ADD_USER, registeruser:false})
        }
}

export function editUser(params: any) {

 //console.log("PARAMETROS QUE LLEGAN"); 
    //console.log(params); 
    return (dispatch:any) => 
    {
       // console.log("URL"); 
        const url = `${ENDPOINTS.EDITUSER}${params.id}`
        //console.log(url); 
       // console.log(url);
        postData(url, params ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
           // console.log("EDITAR_USER"); 
          //  console.log(state);
           // console.log(resp)
            if(state==="success")
            {
            dispatch({type: EDIT_USER, user: _.get(resp, "message.users", {})})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}

export function cleanUser ()

{
    return { type: CLEAN_USER };
}
export function removeUser(id: number): IRemoveUserActionType {
    return { type: REMOVE_USER, id: id };
}

export function changeUserComision(id: number, comision: number): IChangeUserComisionType {
    return { type: CHANGE_USER_COMISION, id: id, comision: comision };
}

export function changeSelectedUser(user: IUser): IChangeSelectedUserActionType {
    return { type: CHANGE_USER_PENDING_EDIT, user: user };
}

export function clearSelectedUser(): IClearSelectedUserActionType {
    return { type: CLEAR_USER_PENDING_EDIT };
}

export function setModificationState(value: UserModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function listusers(guid: string)
{
    return (dispatch:any) => 
    {
        //console.log(guid); 
        //const {GETUSERLIST}= ENDPOINTS
        //console.log('endpoint',GETUSERLIST);
        
        postData(ENDPOINTS.GETUSERLIST,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
           //console.log("Estado listar"); 
         //   console.log(state);
           // console.log("Respuesta Listar"); 

          //  console.log(resp)
            if(state==="success")
            {
            dispatch({type: LIST_USERS, users: _.get(resp, "message.usuarios",[])})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}

export function ExportExcel(guid:string){
    
    return (dispatch:any) => 
    {
       // console.log(guid); 
      
        postDataExcel(ENDPOINTS.EXPORTUSEREXCEL,{guid: guid} ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Usuarios.xls");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}


export function resetPassword(params: any) {

    //console.log("PARAMETROS QUE LLEGAN"); 
       //console.log(params); 
       return (dispatch:any) => 
       {
    
           const url = `${ENDPOINTS.RESETEARPASSWORD}${params.id}`

           postData(url, params ).then((resp)=>{
               const state = _.get(resp, "status", "error"); 

               if(state==="success")
               {
               dispatch({type: EDIT_USER, user: _.get(resp, "message.users", {})})
               } else { 
                   dispatch({ type: SET_ERROR, error: resp })
               }
           })
           .catch((err)=>{ console.log(err); 
               dispatch(setError(err))})
       }
   }

interface IAddUserActionType { type: string, user: IUser };
interface IEditUserActionType { type: string, user: IUser };
interface IRemoveUserActionType { type: string, id: number };
interface IChangeSelectedUserActionType { type: string, user: IUser };
interface IClearSelectedUserActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  UserModificationStatus};
interface IChangeUserComisionType {type: string, id: number, comision: number};
