export interface IUser {
    id: number;
    name: string;
    guid: string, 
    email: string, 
    indentificacion_id: number,
    numero_identificacion:string,
    fecha_nacimiento:Date, 
    telefono: string, 
    provincia_id: number, 
    dealer_id: number, 
    coordinadores_id: number, 
    operador_id: number, 
    withcard: any, 
    num_card: any, 
    state_id: number, 
    tipo_user: number, 
    latitud: string, 
    longitud: string, 
    avatar: string, 
    created_at: Date,
    updated_at: Date, 
   
    provincia: string, 
    dealer: string,

}

export enum UserModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}
