import React, { useState, FormEvent, Dispatch, Fragment } from "react";
import { IStateType, ISimCardState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { ISimCard, SimCardModificationStatus } from "../../store/models/simcard.interface";
import TextInput from "../../common/components/TextInput";
import { editSimCard, clearSelectedSimCard, setModificationState, addSimCard } from "../../store/actions/simcard.action";
import { addNotification } from "../../store/actions/notifications.action";
import NumberInput from "../../common/components/NumberInput";
import Checkbox from "../../common/components/Checkbox";
import SelectInput from "../../common/components/Select";
import { OnChangeModel, ISimCardFormState } from "../../common/types/Form.types";

const SimCardForm: React.FC = () => {
  /*const dispatch: Dispatch<any> = useDispatch();
  const simcards: ISimCardState | null = useSelector((state: IStateType) => state.simcards);
  let simcard: ISimCard | null = simcards.selectedSimCard;
  const isCreate: boolean = (simcards.modificationState === SimCardModificationStatus.Create);
  
  if (!simcard || isCreate) {
    simcard = { id: 0, vendedor: "", fecha_registro: "", comision: 0, serie: "", dealer: ""};
  }

  const [formState, setFormState] = useState({
    vendedor: { error: "", value: simcard.vendedor },
    fecha_registro: { error: "", value: simcard.fecha_registro },
    comision: { error: "", value: simcard.comision },
    serie: { error: "", value: simcard.serie },
    dealer: { error: "", value: simcard.dealer },
  });

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
  }

  function saveUser(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (isFormInvalid()) {
      return;
    }

    let saveUserFn: Function = (isCreate) ? addSimCard : editSimCard;
    saveForm(formState, saveUserFn);
  }

  function saveForm(formState: ISimCardFormState, saveFn: Function): void {
    if (simcard) {
      dispatch(saveFn({
        ...simcard,
        vendedor: formState.vendedor.value,
        fecha_registro: formState.fecha_registro.value,
        comision: formState.comision.value,
        serie: formState.serie.value,
        dealer: formState.dealer.value
      }));

      dispatch(addNotification("Vendedor editado", `Vendedor ${formState.vendedor.value} editado por ti`));
      dispatch(clearSelectedSimCard());
      dispatch(setModificationState(SimCardModificationStatus.None));
    }
  }

  function cancelForm(): void {
    dispatch(setModificationState(SimCardModificationStatus.None));
  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function isFormInvalid(): boolean {
    return (formState.comision.error || formState.fecha_registro.error
      || formState.vendedor.error || formState.serie.error
      || formState.dealer.error || !formState.vendedor.value) as boolean;
}

  return (
    <Fragment>
      <div className="col-xl-7 col-lg-7">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">{(isCreate ? "Crear" : "Editar")} Sim Card</h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveUser}>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.vendedor.value}
                  field="vendedor"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Vendedor"
                  placeholder="Vendedor" />
              </div>
              <div className="form-group">
                <TextInput id="input_description"
                  field = "fecha_registro"
                  value={formState.fecha_registro.value}
                  onChange={hasFormValueChanged}
                  required={false}
                  maxLength={100}
                  label="Fecha de Registro"
                  placeholder="Fecha de Registro" />
              </div>
              <div className="form-group">
                <NumberInput id="input_amount"
                  value={formState.comision.value}
                  field="comision"
                  onChange={hasFormValueChanged}
                  max={5000}
                  min={0}
                  label="Comision" />
              </div>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.serie.value}
                  field="serie"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Serie"
                  placeholder="Serie" />
              </div>
              <div className="form-group">
                <TextInput id="input_email"
                  value={formState.dealer.value}
                  field="dealer"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Dealer"
                  placeholder="Dealer" />
              </div>
              <button className="btn btn-danger" onClick={() => cancelForm()}>Cancelar</button>
              <button type="submit" className={`btn btn-success left-margin ${getDisabledClass()}`}>Guardar</button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>*/
    return (<div>Hola</div>
  );
};

export default SimCardForm;
