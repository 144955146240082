export interface INews {
    id: number;
    titulo: string;
    descripcion: string;
    url: string;
    vistas:number, 
    state_id: number, 
    banner: string, 
    like: string, 
    sate_id: number, 
    created_at: Date, 
    updated_at: Date, 
     
}

export enum NewsModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}
