import React, {useState} from "react"; 
import Alert from 'react-bootstrap/Alert'; 
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
export default function Loading(props: any) {

    const {  visible, setVisible } = props; 
  
    return (
      <Modal
      show={visible} 
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Alert show={visible}  style={{marginBottom: 0, paddingBottom: 20,}}>
          <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
          </div>
        </Alert>
        </Modal>

    );
  }
  

