import React from "react";
import { useSelector } from "react-redux";
import { IStateType, IPaymentState } from "../../store/models/root.interface";
import { IPayment } from "../../store/models/payment.interface";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { GRID_DEFAULT_LOCALE_TEXT } from "../Utilities/LocaleDataGrid";
import { Typography, Grid } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
export type UsuarioListProps = {
  onSelect?: (usuario: IPayment) => void;
  children?: React.ReactNode;
};

function PaymentList(props: UsuarioListProps): JSX.Element {
  const payments: IPaymentState = useSelector(
    (state: IStateType) => state.payments
  );

  const paymentElements: (JSX.Element | null)[] = payments.payments.map(
    (payment) => {
      if (!payment) {
        return null;
      }
      return (
        <tr
          className={`table-row ${
            payments.selectedPayment &&
            payments.selectedPayment.id === payment.id
              ? "selected"
              : ""
          }`}
          onClick={() => {
            if (props.onSelect) props.onSelect(payment);
          }}
          key={`payment_${payment.id}`}
        >
          <th scope="row">{payment.id}</th>
          <td>{payment.codigo_pago}</td>
          <td>{moment(payment.correspondiente_a).format("ll")}</td>
          <td>{payment.cantidad_simcards}</td>
          <td>{payment.cantidad_usuarios}</td>
          <td>{payment.monto}</td>
          <td>{payment.creado_por}</td>
          <td>{payment.estado.toUpperCase()}</td>
        </tr>
      );
    }
  );

  const columns = [
    {
      field: "id",
      headerName: "#",
      renderCell: (params: { value: any }) => (
        <Typography> {params.value}</Typography>
      ),
      width: 200,
    },
    {
      field: "correspondiente_a",
      headerName: "Correspondiente a",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography> {moment(params.value).format("ll")}</Typography>
      ),
    },
    {
      field: "cantidad_simcards",
      headerName: "Cantidad de SimCards",
      width: 200,
    },
    {
      field: "cantidad_usuarios",
      headerName: "Cantidad de Usuarios",
      width: 200,
    },
    {
      field: "monto",
      headerName: "Monto",
      width: 200,
    },
    {
      field: "creado_por",
      headerName: "Creador Por",
      width: 200,
    },
/*     {
      field: "estado",
      headerName: "Estado",
      width: 200,
    }, */
    {
      field: "id",
      headerName: "Reportes",
      width: 120,
      renderCell: (params: { value: any }) => (
        <Link
          to={{
            pathname: `/simcardspagadas`,
            state: { id: params.value },
          }}
        >
          <Visibility style={{ fontSize: 25 }} color="primary" />
        </Link>
      ),
      sortable: false,
    },
  ];

  return (
    <div className="table-responsive portlet">
      <Grid container direction="column" style={{ height: "80vh" }}>
        <DataGrid
          rows={payments.payments}
          columns={columns}
          autoPageSize={true}
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          onCellClick={(params, event) => {
            
            localStorage.setItem("simcardspagadas", JSON.stringify(params.row))
  
          }}
        />
      </Grid>
    </div>
  );
}

export default PaymentList;
