
const isDev = process.env.NODE_ENV === 'development';

const URI = isDev
  ? "https://api.comisionesmasmovil.com"
  : "https://api.comisionesmasmovil.com"; 
 

/* const URI="http://applabs.io"
export const STORAGE ="http://applabs.io/storage/"; */

 export const STORAGE = isDev 
? "https://api.comisionesmasmovil.com/storage/"
: "https://api.comisionesmasmovil.com/storage/";
 

export const ENDPOINTS = {
  LOGIN: URI + "/api/web/login/usuarios",
  INITIALREPORT: URI + "/api/web/reportes/inicio",
  GETUSERLIST: URI + "/api/web/obtener/usuarios",
  GETSIMCARDS: URI + "/api/web/obtener/simcards",
  GETSIMCARDSFILTRADAS: URI + "/api/web/obtener/mantenedorsimcards",
  GETALLNEWS: URI + "/api/web/consultar/noticias/all",
  ADDNEWS: URI + "/api/web/crear/noticias",
  GETDEALERS: URI + "/api/web/obtener/dealers/all",


  EXPORTUSEREXCEL: URI + "/api/exportar/excel/usuarios",
  EXPORTSIMCARDS: URI + "/api/exportar/excel/simcards",
  EXPORTCABPAGOS: URI + "/api/exportar/excel/cabpagos",
  EXPORTDEALERS: URI + "/api/exportar/excel/dealers",
  EXPORTSIMPAGADAS: URI + "/api/exportar/excel/simcards/pagadas",

  GETNEWSBYID: URI + "/api/consultar/noticias/IDnoticias",
  EDITNEWS: URI + "/api/web/actualizar/noticias/IDnoticia/",
  GET_COORDINADOR_LIST: URI + "/api/web/obtener/coordinadores/IDprovincia/",  
  GET_PROVINCES: URI +"/api/web/obtener/provincias", 
  EDITUSER : URI + "/api/web/actualizar/IDusuario/",
  PAYMENDHEADER: URI + "/api/web/crear/Cabpagos",
  UPLOADPAYMENTDETAILS: URI +  "/api/importar/file/pagos",
  OBTENERCATALOGOPAGOS: URI + "/api/web/obtener/Cabpagos",
  UPLOADCOMISSIONS : URI + "/api/importar/file/comisiones", 
  GETDEALERID: URI + "/api/web/obtener/dealers/IDdealer/", 
  GETDISTRITOS: URI + "/api/web/obtener/distritos", 
  GETCORREGIMIENTOS: URI + "/api/web/obtener/corregimientos",
  GETDEALER: URI + "/api/web/actualizar/dealers/IDdealer/",
  CREATEUSER: URI + "/api/crear/usuario",

  RESETEARPASSWORD: URI + "/api/web/resetear/password/",

};


