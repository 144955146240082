import { ENDPOINTS } from "../../common/types/Endpoints";
import { postData, postDataExcel } from "../../utils/utils";
import { IPayment, PaymentModificationStatus } from "../models/payment.interface";
import { SET_ERROR, setError } from "./errorActions";
import _ from "lodash"; 
export const ADD_PAYMENT: string = "ADD_PAYMENT";
export const EDIT_PAYMENT: string = "EDIT_PAYMENT";
export const REMOVE_PAYMENT: string = "REMOVE_PAYMENT";
export const CHANGE_PAYMENT_COMISION: string = "CHANGE_PAYMENT_COMISION";
export const CHANGE_PAYMENT_PENDING_EDIT: string = "CHANGE_PAYMENT_PENDING_EDIT";
export const CLEAR_PAYMENT_PENDING_EDIT: string = "CLEAR_PAYMENT_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const UPDATE_PAYMENT_DETAILS: string = "UPDATE_PAYMENT_DETAILS"; 
export const CLEAN_NEW_PAYMENT: string = "CLEAN_NEW_PAYMENT"; 
export const CLEAN_UPLOAD_FILE : string = "CLEAN_UPLOAD_FILE";
export const LIST_PAYMENTS : string = "LIST_PAYMENTS"; 

export function addPayment(payment: any) {

    return (dispatch:any) => 
    {
        postData(ENDPOINTS.PAYMENDHEADER, payment ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
     /*        console.log("Estado listar"); 
            console.log(state);
            console.log("Respuesta Listar");  */

           // console.log(resp)
            if(state==="success")
            {
            dispatch({type: ADD_PAYMENT, payment: _.get(resp, "message.cabpagos",{})})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
    
}

export function uploadPaymentDetails(params: any){

    
    return (dispatch:any) => 
    {
        let formdata = new FormData();

        formdata.append("pagos",params.pagos);
        formdata.append("guid", params.guid);
        formdata.append("pago_id", params.pago_id);
      
        const resultado =  fetch(ENDPOINTS.UPLOADPAYMENTDETAILS, {
            method: "post",
            body: formdata,
          })
            .then(async (response) => {
              await response.json().then(async (data) => {
                  //console.log(data); 
                const state = _.get(data, "status", "error"); 
               if(state ==="success")
               {
               // console.log(data);
                const pagos = _.get(data, "message.pagos", {});
              //  console.log(pagos); 
               dispatch({ type: UPDATE_PAYMENT_DETAILS, uploadfile: true });
               }
               else {
                dispatch({ type: UPDATE_PAYMENT_DETAILS, uploadfile: false });
                dispatch({ type: SET_ERROR, error: data })
               }
              });
            })
            .catch((err) => {
              //  console.log(err); 
                dispatch({ type: SET_ERROR, error: err })
            });
    }
}


export function listpayments (guid: string){
    return (dispatch:any) => 
    {
        postData(ENDPOINTS.OBTENERCATALOGOPAGOS,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("Estado listar"); 
            console.log(state);
            console.log("Respuesta Listar"); 

            console.log(resp)
            if(state==="success")
            {
            dispatch({type: LIST_PAYMENTS, payments: _.get(resp, "message.cabpagos",[])})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}

export function cleanPayment() {
    return { type: CLEAN_NEW_PAYMENT };
}


export function cleanuploadfile() {
    return { type: CLEAN_UPLOAD_FILE };
}


export function editPayment(payment: IPayment): IEditPaymentActionType {
    return { type: EDIT_PAYMENT, payment: payment };
}

export function removePayment(id: number): IRemovePaymentActionType {
    return { type: REMOVE_PAYMENT, id: id };
}

export function changePaymentComision(id: number, comision: number): IChangePaymentComisionType {
    return { type: CHANGE_PAYMENT_COMISION, id: id, comision: comision };
}

export function changeSelectedPayment(payment: IPayment): IChangeSelectedPaymentActionType {
    return { type: CHANGE_PAYMENT_PENDING_EDIT, payment: payment };
}

export function clearSelectedPayment(): IClearSelectedPaymentActionType {
    return { type: CLEAR_PAYMENT_PENDING_EDIT };
}

export function setModificationState(value: PaymentModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}


export function ExportExcel(guid:string){
    
    return (dispatch:any) => 
    {
        console.log(guid); 
      
        postDataExcel(ENDPOINTS.EXPORTCABPAGOS,{guid: guid} ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "PagosExcel.xls");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}
export function ExportSimcardsPagada(guid:string,pago:number): (dispatch: any) => void{
    
    return (dispatch:any) => 
    {
    
      
        postDataExcel(ENDPOINTS.EXPORTSIMPAGADAS,{guid: guid,pagoid:pago} ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "PagosSimcardsExcel.xls");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}
interface IAddPaymentActionType { type: string, payment: IPayment };
interface IEditPaymentActionType { type: string, payment: IPayment };
interface IRemovePaymentActionType { type: string, id: number };
interface IChangeSelectedPaymentActionType { type: string, payment: IPayment };
interface IClearSelectedPaymentActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  PaymentModificationStatus};
interface IChangePaymentComisionType {type: string, id: number, comision: number};
