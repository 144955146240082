export interface ISimCard {
    name: string;
    id: number;
    vendedor: string;
    fecha_registro: string;
    serie: string;
    dealer: string;
    user_id: number;
    estado: string;
    terminada: string;
    pagada: string;
    comision: string;
    rango_recarga: string;
    islte: string;
    origen: string;
    tipo_operacion: string;
    created_at: Date;
    updated_at: Date;
    coordinadores_id: number;
    nombre: string; 
}
export interface ISimCardParameter {
    guid: any;
    estado: number;
    anio: number;
    mes: number;
    comision: number;
    canal:number;
}
export enum SimCardModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}
