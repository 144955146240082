import React, { Fragment, Dispatch, useState, useEffect } from "react";
import UserList from "./UsersList";
import UserForm from "./UsersForm";
import TopCard from "../../common/components/TopCard";
import "./Users.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IUserState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import Popup from "reactjs-popup";
import {
  removeUser,
  clearSelectedUser,
  setModificationState,
  changeSelectedUser,
  listusers,
  ExportExcel,
} from "../../store/actions/users.action";
import { addNotification } from "../../store/actions/notifications.action";
import {
  UserModificationStatus,
  IUser,
} from "../../store/models/user.interface";
import _ from "lodash";
import Loading from "../Utilities/Loading";
import { Link } from "react-router-dom";

const Users: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const users: IUserState = useSelector((state: IStateType) => state.users);
  const user: string = useSelector((state: IStateType) => state.account.user);
  const [visible, setvisible] = useState(false);

  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    setvisible(true);
    //console.log(_.get(user, "guid", ""));
    
    dispatch(listusers(_.get(user, "guid", "")));
  }, []);

  useEffect(() => {
    if (users.users) {
      if (visible) {
        setvisible(false);
      }
    }
  }, [users.users]);

  useEffect(() => {
    dispatch(clearSelectedUser());
    dispatch(updateCurrentPath("users", "list"));
  }, [path.area, dispatch]);

  function onUserSelect(usuario: IUser): void {
    dispatch(changeSelectedUser(usuario));
    dispatch(setModificationState(UserModificationStatus.None));
  }

  function onUserRemove() {
    if (users.selectedUser) {
      setPopup(true);
    }
  }

  function DownladExcel() {

    dispatch(ExportExcel(_.get(user, "guid", "")));
  }

  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Administrar Usuarios</h1>

      {/* <div className="row">
        <TopCard title="USUARIO COUNT" text={`${numberItemsCount}`} icon="box" class="primary" />
        <TopCard title="USUARIO AMOUNT" text={`${totalAmount}`} icon="warehouse" class="danger" />
        <TopCard title="SUMMARY PRICE" text={`$${totalPrice}`} icon="dollar-sign" class="success" />
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4">
          <button
            className="btn btn-lg top-button mr-2 float-right"
            onClick={DownladExcel}
          >
            <i className="fas fa-file-excel icon-green"></i> Descargar Excel
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Listado</h6>
              <div className="header-buttons">
                <Link to="/addusers">
                  <button className="btn btn-success btn-green">
                    <i className="fas fa fa-plus"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <UserList onSelect={onUserSelect} />
            </div>
          </div>
        </div>
        {users.modificationState === UserModificationStatus.Create ||
        (users.modificationState === UserModificationStatus.Edit &&
          users.selectedUser) ? (
          <UserForm />
        ) : null}
      </div>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Are you sure?</div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!users.selectedUser) {
                  return;
                }
                dispatch(
                  addNotification(
                    "Product removed",
                    `Product ${users.selectedUser.name} was removed`
                  )
                );
                dispatch(removeUser(users.selectedUser.id));
                dispatch(clearSelectedUser());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
      <Loading visible={visible} setvisible={setvisible} />
    </Fragment>
  );
};

export default Users;
