import React, {
  useState,
  FormEvent,
  Dispatch,
  Fragment,
  ChangeEvent,
  useEffect,
} from "react";
import { IStateType, IPaymentState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import {
  cleanPayment,
  addPayment,
  uploadPaymentDetails,
  cleanuploadfile,
} from "../../store/actions/payments.action";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import _, { isEmpty, size } from "lodash";
import Alert from "../Utilities/Alert";
import Loading from "../Utilities/Loading";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { setHideError } from "../../store/actions/errorActions";

const getUniqueKey = () => {
  let now = new Date();
  let timestamp = null;
  timestamp = now.getFullYear().toString(); // 2011
  timestamp += (now.getMonth() < 9 ? "0" : "") + now.getMonth().toString(); // JS months are 0-based, so +1 and pad with 0's
  timestamp += (now.getDate() < 10 ? "0" : "") + now.getDate().toString(); // pad with a 0
  timestamp +=
    now.getHours() +
    now.getMinutes() +
    now.getSeconds() +
    now.getMilliseconds();
  return timestamp;
};

const initialstate = {
  codigo_pago: getUniqueKey(),
  correspondiente_a: new Date(),
  cantidad_simcards: 0,
  cantidad_usuarios: 0,
  monto: 0,
};

const initialstatedetails = {
  guid: "",
  pagos: {},
  pago_id: "",
};

const PaymentForm: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const payments: IPaymentState | null = useSelector(
    (state: IStateType) => state.payments
  );
  const user: string = useSelector((state: IStateType) => state.account.user);
  const payment: IPaymentState = useSelector(
    (state: IStateType) => state.payments.payment
  );
  const uploadfile: boolean = useSelector(
    (state: IStateType) => state.payments.uploadfile
  );
  const error = useSelector((state: IStateType) => state.error.error);

  console.log("**********PAYMENT*****************");
  console.log(payment);

  const [state, setstate] = useState(initialstate);
  const [statedetails, setstatedetails] = useState(initialstatedetails);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);
  const history = useHistory();

  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });

  useEffect(() => {
    dispatch(cleanPayment());
    dispatch(setHideError());
    dispatch(cleanuploadfile());
  }, []);

  useEffect(() => {
    if (uploadfile) {
      setmensaje({
        ...mensaje,
        title: "Registro exitoso",
        description: "Pagos Subidos Exisosamente",
        type: "success",
      });
      setshowalert(true);
      dispatch(cleanuploadfile());
      dispatch(cleanPayment());
      dispatch(cleanuploadfile());
    }
  }, [uploadfile]);

  useEffect(() => {
    if (!isEmpty(error)) {
      setmensaje({
        ...mensaje,
        title: "Ha ocurrido un error",
        description: "Ha ocurrido un error, favor inténtelo nuevamente",
        type: "danger",
      });
      setshowalert(true);
    }
  }, [error]);

  console.log("**********PAYMENT*****************");
  console.log(payment);

  function closeDialog() {
    history.push("/payments");
  }
  function saveForm(): void {
    const {
      codigo_pago,
      correspondiente_a,
      cantidad_simcards,
      cantidad_usuarios,
      monto,
    } = state;

    if (isEmpty(correspondiente_a)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Debe ingresar la fecha de pago",
        type: "warning",
      });
      setshowalert(true);
    } else if (cantidad_simcards <= 0) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Debe ingresar la cantidad de simcards correctamente",
        type: "warning",
      });
      setshowalert(true);
    } else if (cantidad_usuarios <= 0) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Debe ingresar la cantidad de usuarios correctamente",
        type: "warning",
      });
      setshowalert(true);
    } else if (monto <= 0) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Debe ingresar monto del pago",
        type: "warning",
      });
      setshowalert(true);
    } else {
      const paymentheader: any = {
        codigo_pago: codigo_pago,
        correspondiente_a: correspondiente_a,
        cantidad_simcards: cantidad_simcards,
        cantidad_usuarios: cantidad_usuarios,
        monto: monto,
        state_id: 4,
        guid: _.get(user, "guid", ""),
      };
      dispatch(addPayment(paymentheader));
    }
  }

  function onValueChanged(e: ChangeEvent<HTMLInputElement>): void {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  }

  function fileSelectedHandler(e: ChangeEvent<HTMLInputElement>) {
    let file = e.target.files;
    console.log(file[0]);
    //setstatedetails({...statedetails, pagos: file[0] , guid: _.get(user, "guid", "") , pago_id: "1"});

    const params = {
      pagos: file[0],
      guid: _.get(user, "guid", ""),
      pago_id: _.get(payment, "id", 0),
    };
    dispatch(uploadPaymentDetails(params));
    console.log("Se ejecutó la subida");
  }

  return (
    <Form>
      <div className="col-xl-7 col-lg-7">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              Crear Archivo de pago
            </h6>
          </div>
          <div className="card-body">
            <Form.Group>
              <Form.Label>Código de Pago</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código De Pago"
                name="codigo_pago"
                onChange={onValueChanged}
                value={state.codigo_pago}
                disabled
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Correspondiente a</Form.Label>
              <Form.Control
                type="date"
                placeholder="Correspondiente a"
                name="correspondiente_a"
                onChange={onValueChanged}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cantidad De SimCards</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cantidad De Simcards"
                name="cantidad_simcards"
                onChange={onValueChanged}
                value={state.cantidad_simcards}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cantidad De Usuarios</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cantidad de Usuarios"
                name="cantidad_usuarios"
                onChange={onValueChanged}
                value={state.cantidad_usuarios}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type="number"
                placeholder="Monto"
                name="monto"
                onChange={onValueChanged}
                value={state.monto}
              />
            </Form.Group>
            {!isEmpty(payment) && (
              <Form.Group>
                Favor Suba el Archivo para terminar el proceso<br></br>
                <input
                  type="file"
                  onChange={fileSelectedHandler}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Form.Group>
            )}
            {!payment && (
              <Button variant="primary" onClick={saveForm}>
                Agregar Nuevo Pago
              </Button>
            )}
          </div>
        </div>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </div>
    </Form>
  );
};

export default PaymentForm;
