import React from "react";
import { useSelector } from "react-redux";
import { IStateType, IUserState } from "../../store/models/root.interface";
import { IUser } from "../../store/models/user.interface";
import moment from "moment";
import { Link } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { Typography, Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { GRID_DEFAULT_LOCALE_TEXT } from "../Utilities/LocaleDataGrid";

export type UserListProps = {
  onSelect?: (user: IUser) => void;
  children?: React.ReactNode;
};

function UserList(props: UserListProps): JSX.Element {
  const users: IUserState = useSelector((state: IStateType) => state.users);
  const userElements: (JSX.Element | null)[] = users.users.map((user) => {
    if (!user) {
      return null;
    }
    return (
      <tr
        className={`table-row ${
          users.selectedUser && users.selectedUser.id === user.id
            ? "selected"
            : ""
        }`}
        onClick={() => {
          if (props.onSelect) props.onSelect(user);
        }}
        key={`user_${user.id}`}
      >
        <th scope="row">{user.id}</th>
        <td>{user.name}</td>
        <td>{moment(user.created_at).format("ll")}</td>
        <td> - </td>
        <td>{user.provincia}</td>
        <td>{user.dealer}</td>
        <td>
          <Link to={`/editusers`}>
            <BsPencil
              onClick={() =>
                localStorage.setItem("editusers", JSON.stringify(user))
              }
            />
          </Link>
        </td>
      </tr>
    );
  });

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      renderCell: (params: { value: any }) => (
        <Typography> {params.value}</Typography>
      ),
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Fecha de Registro",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography> {moment(params.value).format("ll")}</Typography>
      ),
    },
    {
      field: "comision",
      headerName: "Comision",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography>${params.value}</Typography>
      ),
    },
    {
      field: "numero_identificacion",
      headerName: "N. Identificacion",
      width: 200,
    },
    {
      field: "dealer",
      headerName: "Dealer",
      width: 150,
    },
    {
      field: "id",
      headerName: "Editar",
      width: 90,
      renderCell: (params: { value: any }) => (
        <Link
          to={{
            pathname: `/editusers`,
            state: { id: params.value },
          }}
        >
          <EditIcon style={{ fontSize: 25 }} color="primary" />
        </Link>
      ),
      sortable: false,
    },
  ];

  return (
    <div className="table-responsive portlet">
      <Grid container direction="column" style={{ height: "80vh" }}>
        <DataGrid 
        rows={users.users} 
        columns={columns}
        autoPageSize={true} 
        localeText={GRID_DEFAULT_LOCALE_TEXT}
        onCellClick={(params, event) => {
          localStorage.setItem("editusers", JSON.stringify(params.row))

        }}
        />
 
      </Grid>
    </div>
  );
}

export default UserList;
