import React, { useState,useEffect, FormEvent, Dispatch } from "react";
import { OnChangeModel } from "../../common/types/Form.types";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/account.actions";
import TextInput from "../../common/components/TextInput";
import logo from "../../assets/mas_movil_orange.svg";
import ellipse from "../../assets/Ellipse 1.svg";
import simCard from "../../assets/Icon awesome-sim-card.svg";
import banner from "../../assets/masMovil.png";
import Alert from "../Utilities/Alert"; 
import _ , {isEmpty} from "lodash"; 
import { IStateType } from "../../store/models/root.interface";
import Loading from "../Utilities/Loading"; 

const Login: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const error = useSelector((state: IStateType) => state.error.error);


  const [formState, setFormState] = useState({
    email: { error: "", value: "" },
    password: { error: "", value: "" },
  });

  function closeDialog(){
    console.log(".");
  }

  const [mensaje, setmensaje] = useState({
    title:"", 
    description: "", 
    type:"",
  })
  const [showalert, setshowalert] = useState(false); 
  const [visible, setvisible] = useState(false); 


  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
  }

   useEffect(() => {
     console.log("ERROR"); 
     console.log(error); 
    const errortext : string = _.get(error, "message.detail.Usuario",  "");
    const errortext2 : string = _.get(error, "message.detail.password",  "");

    if(errortext || errortext2)
    {
      setmensaje({...mensaje, title: "Validación de datos", description: "Usuario o Contraseña Incorrectos.\nFavor verifique e Inténtelo nuevamente.", type: "danger"})
      setshowalert(true)
    } 

  }, [error])

  async function  submit(e: FormEvent<HTMLFormElement>): Promise<any>  {
    e.preventDefault();
    setvisible(true)
 
    if(isEmpty(formState.email.value))
    {  
      setmensaje({...mensaje, title: "Validación de datos", description: "Debe ingresar su correo electrónico", type: "warning"})
      setshowalert(true); 
    } else if (!validaremail(formState.email.value))
    {
      setmensaje({...mensaje, title: "Validación de datos", description: "Debe ingresar un correo electrónico válido", type: "warning"})
      setshowalert(true); 
    }
    else  if (isEmpty(formState.password.value)) 
    {
      setmensaje({...mensaje, title: "Validación de datos", description: "Debe ingresar su contraseña", type: "warning"})
      setshowalert(true); 
    } else { 

       dispatch(login(formState.email.value,formState.password.value));
       console.log(error); 
    }
    setvisible(false)

  //  
  }

/*   function isFormInvalid() {
    return (
      formState.email.error ||
      formState.password.error ||
      !formState.email.value ||
      !formState.password.value
    );
  } */

  
 const validaremail = (text:string) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

/*   function getDisabledClass(): string {
    let isError: boolean = isFormInvalid() as boolean;
    return isError ? "" : "disabled";
  } */

  return (
    <div className="container-fluid">

      <div className="row">

        <div className="col-sm-6 px-0 d-none d-sm-block">
          <img src={logo} className="logo" />
          <img src={ellipse} className="ellipse" />
          <img src={simCard} className="simCard" />
          <img src={banner} className="login-img" />
        </div>
        <div className="col-sm-6 login-section-wrapper">
          <div className="login-wrapper">
            <div className="text-center">
              <h1 className="h2 font-color-primary mb-4">
                Sistema de Administración de Comisiones
              </h1>
            </div>
            <form className="user" onSubmit={submit}>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  field="email"
                  value={formState.email.value}
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={100}
                  label="Correo"
                  placeholder=""
                />
              </div>
              <div className="form-group">
                <TextInput
                  id="input_password"
                  field="password"
                  value={formState.password.value}
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={100}
                  type="password"
                  label="Contraseña"
                  placeholder=""
                />
              </div>
              <div className="form-group">
                <div className="text-center">
                  <label className="label">¿Olvidaste tu contraseña?</label>
                </div>
              </div>
              <button
               // className={`btn color-primary btn-user btn-block ${getDisabledClass()}`}
                className={`btn color-primary btn-user btn-block`}
                type="submit"
              >
                Iniciar Sesión
              </button>
            </form>
          </div>
        </div>
      </div>
      <Alert title={mensaje.title} description={mensaje.description} visible = {showalert} setVisible={setshowalert} type={mensaje.type} closeDialog ={closeDialog}/>
      <Loading visible={visible} setvisible={setvisible} />
    </div>
  );
};

export default Login;
