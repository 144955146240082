import React, { PropsWithChildren, ReactElement } from "react";
import { ICardProperties } from "../types/TopCard.types";

function TopCard(props: PropsWithChildren<ICardProperties>): ReactElement {
    return (
        <div className="col-md-12 mb-4">
            <div className="col mr-2">
                <i className={`fas fa-${props.icon} fa-2x font-color-primary`}></i>
                <div className="text-xs font-weight-bold font-color-primary text-uppercase mb-1">{props.title}</div>
                <div className="h5 mb-0 font-weight-bold font-color-secundary">{props.text}</div>
            </div>
        </div>
    );
}


export default TopCard;
