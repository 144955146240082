import { ENDPOINTS } from "../../common/types/Endpoints";
import { IDealer, DealerModificationStatus } from "../models/dealer.interface";
import { setError, SET_ERROR } from "./errorActions";
import _ from "lodash"; 
import { getData, postData, postDataExcel } from "../../utils/utils";

export const ADD_DEALER: string = "ADD_DEALER";
export const EDIT_DEALER: string = "EDIT_DEALER";
export const REMOVE_DEALER: string = "REMOVE_DEALER";
export const CHANGE_DEALER_COMISION: string = "CHANGE_DEALER_COMISION";
export const CHANGE_DEALER_PENDING_EDIT: string = "CHANGE_DEALER_PENDING_EDIT";
export const CLEAR_DEALER_PENDING_EDIT: string = "CLEAR_DEALER_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const LIST_DEALERS: string = "LIST_DEALERS"; 
export const GET_DEALERBYID : string ="GET_DEALERBYID"; 

export function addDealer(dealer: IDealer): IAddDealerActionType {
    return { type: ADD_DEALER, dealer: dealer };
}

export function editDealer(state: any) 
{
    return (dispatch:any) => 
    {
      //  console.log(`${ENDPOINTS.GETDEALER}${state.id}`); 

        postData(`${ENDPOINTS.GETDEALER}${state.id}`, state ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("Respuesta EDITAR"); 
            console.log(resp)
            if(state==="success")
            {

            dispatch({type: EDIT_DEALER, dealer: _.get(resp, "message.dealers",[])})

            } else 
            { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}

export function removeDealer(id: number): IRemoveDealerActionType {
    return { type: REMOVE_DEALER, id: id };
}

export function changeDealerComision(id: number, comision: number): IChangeDealerComisionType {
    return { type: CHANGE_DEALER_COMISION, id: id, comision: comision };
}

export function changeSelectedDealer(dealer: IDealer): IChangeSelectedDealerActionType {
    return { type: CHANGE_DEALER_PENDING_EDIT, dealer: dealer };
}

export function clearSelectedDealer(): IClearSelectedDealerActionType {
    return { type: CLEAR_DEALER_PENDING_EDIT };
}

export function setModificationState(value: DealerModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function listDealers (guid: string) {

    return (dispatch:any) => 
    {
      /*   console.log(ENDPOINTS.GETDEALERS); 
        console.log(guid);  */
        
        postData(ENDPOINTS.GETDEALERS,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
    /*         console.log("Respuesta DEALERS"); 
            console.log(resp) */
            if(state==="success")
            {
            dispatch({type: LIST_DEALERS, dealers: _.get(resp, "message.dealers",[])})


            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}


 export function getDealerbyId (guid: string, id: string) {

    return (dispatch:any) => 
    {
        console.log(ENDPOINTS.GETDEALERS); 
        console.log(guid); 
        
        postData(`${ENDPOINTS.GETDEALERID}${id}`,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("GET DEALERS"); 
            console.log(resp)
            if(state==="success")
            {
            dispatch({type: GET_DEALERBYID, dealer: _.get(resp, "message.dealers",{})})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}



export function ExportExcel(guid:string){
    
    return (dispatch:any) => 
    {
        console.log(guid); 
      
        postDataExcel(ENDPOINTS.EXPORTDEALERS, {guid: guid} ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Dealers.xls");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}

interface IAddDealerActionType { type: string, dealer: IDealer };
interface IEditDealerActionType { type: string, dealer: IDealer };
interface IRemoveDealerActionType { type: string, id: number };
interface IChangeSelectedDealerActionType { type: string, dealer: IDealer };
interface IClearSelectedDealerActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  DealerModificationStatus};
interface IChangeDealerComisionType {type: string, id: number, comision: number};
