import React, { FormEvent, ChangeEvent, useState, useEffect } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import _, { head, isEmpty, size } from "lodash";
import Alert from "../Utilities/Alert";
import {
  addNews,
  blanknew,
  editNewsActions,
} from "../../store/actions/news.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utilities/Loading";
import {
  getcoordinadores,
  getDealerList,
  getProvinces,
} from "../../utils/utils";
import { IStateType } from "../../store/models/root.interface";
import { setHideError } from "../../store/actions/errorActions";
import { editUser, cleanUser,resetPassword } from "../../store/actions/users.action";
import { useHistory, useParams } from "react-router-dom";
import { STORAGE } from "../../common/types/Endpoints";
import { INews } from "../../store/models/news.interface";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Select from "react-select";

const initialstate = {
  id: 0,
  name: "",
  guid: "",
  email: "",
  indentificacion_id: 0,
  numero_identificacion: "",
  fecha_nacimiento: new Date(),
  telefono: "",
  provincia_id: 0,
  dealer_id: 0,
  coordinadores_id: 0,
  coordinadores: "",
  operador_id: 0,
  withcard: "",
  num_card: "",
  estado: "",
  estado_id: 1,
  state_id: 1,
  tipo_user: "",
  tipo_user_description: "",
  latitud: "",
  longitud: "",
  avatar: "",
  created_at: new Date(),
  updated_at: new Date(),
  provincia: "",
  dealer: "",
  operador: "",
};

export default function EditUsers() {
  const [state, setstate] = useState(initialstate);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);
  const [coordinadoreslist, setcoordinadoreslist] = useState([]);
  const [provincelist, setprovincelist] = useState([]);
  const [dealerlist, setdealerlist] = useState([]);
  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });

  const dispatch = useDispatch();
  const error = useSelector((state: IStateType) => state.error.error);
  const currentnew = useSelector((state: IStateType) => state.news.currentnew);
  const history = useHistory();
  const user: string = useSelector((state: IStateType) => state.account.user);
  const userstore: string = useSelector(
    (state: IStateType) => state.users.user
  );

/*   console.log("********ESTADO*********");
  console.log(state); */
//console.log('usestore',userstore)
  useEffect(() => {
    if (!error) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Ha ocurrido un error en el proceso de registro",
        type: "danger",
      });
      setshowalert(true);
    }
  }, [error]);
  
  useEffect(() => {
    dispatch(setHideError());
    dispatch(blanknew());
    dispatch(cleanUser());
    const id = localStorage.getItem("editusers");
    //console.log("*****ID******");
    
    //console.log(id);
    setstate({ ...state, ...JSON.parse(id) });
    (async () => {
      setcoordinadoreslist(await getcoordinadores(1));
      setdealerlist(await getDealerList(_.get(user, "guid", "")));
      setprovincelist(await getProvinces());
    })();
  }, []);



  useEffect(() => {
    if (userstore) {
      setmensaje({
        ...mensaje,
        title: "Actualización exitosa",
        description: "Usuario actualizado correctamente",
        type: "success",
      });
      setshowalert(true);
      setvisible(false);
      dispatch(setHideError());
      dispatch(blanknew());
      dispatch(cleanUser());
    }
  }, [userstore]);



  const ProvinceDropDown = () => {
    return (
      <DropdownButton id="dropdown-basic-button" title={state.provincia}>
        {provincelist &&
          provincelist.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({
                  ...state,
                  provincia_id: Number(eventkey),
                  provincia: item.provincia,
                });
              }}
            >
              {item.provincia}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  const DealerDropDown = () => {
    let dealeroption: any = [];
    dealerlist.map((item) =>
      dealeroption.push({ label: item.nombre, value: item.id })
    );

    return (
      <Select
        options={dealeroption}
        onChange={(opt: any) => {
          setstate({
            ...state,
            dealer_id: Number(opt.value),
            dealer: opt.label,
          });
        }}
        defaultValue={{ label: state.dealer, value: state.dealer_id }}
      />
    );
  };

  const CoordinadoresDropdown = () => {
    let titulocoordinador = "";

    if (coordinadoreslist) {
      coordinadoreslist.map((item) => {
        if (Number(item.id) === Number(state.coordinadores)) {
          titulocoordinador = item.nombre;
        }
      });
    }

    return (
      <DropdownButton
        id="dropdown-basic-button"
        className=" w-100 btn-block"
        title={isEmpty(titulocoordinador) ? "Seleccione..." : titulocoordinador}
      >
        {coordinadoreslist &&
          coordinadoreslist.map((item) => (
            <Dropdown.Item
              key={item.id.toString()}
              eventKey={item.id.toString()}
              onSelect={(eventkey, e) => {
                setstate({ ...state, coordinadores: eventkey });
              }}
            >
              {item.nombre}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  function save() {
    const {
      id,
      name,
      guid,
      email,
      indentificacion_id,
      numero_identificacion,
      fecha_nacimiento,
      telefono,
      provincia_id,
      dealer_id,
      coordinadores_id,
      coordinadores,
      operador_id,
      withcard,
      num_card,
      state_id,
      tipo_user,
      latitud,
      longitud,
      avatar,
      created_at,
      updated_at,
      provincia,
      dealer,
    } = state;

    if (isEmpty(name)) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor ingrese el valor para el campo título",
        type: "warning",
      });
      setshowalert(true);
    } else {
      const params = {
        guid: _.get(user, "guid", ""),
        id: id,
        name: name,
        email: email,
        indentificacion_id: indentificacion_id,
        numero_identificacion: numero_identificacion,
        fecha_nacimiento: fecha_nacimiento,
        telefono: telefono,
        provincia_id: provincia_id,
        dealer_id: dealer_id,
        coordinadores_id: coordinadores,
        operador_id: operador_id,
        withcard: withcard,
        num_card: num_card,
        state_id: state_id,
        tipo_user: tipo_user,
        latitud: latitud,
        longitud: longitud,
      };

     /*  console.log("ESTOS SON LOS PARÁMETROS");
      console.log(params); */

      dispatch(editUser(params));

      setvisible(true);
    }
  }

  function closeDialog() {
    history.push("/users");
  }

  function onValueChanged(e: ChangeEvent<HTMLInputElement>): void {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  }

  const handleReset=()=>{
    const usuarioEditar = localStorage.getItem("editusers");
    const {id}=JSON.parse(usuarioEditar);
/*     setmensaje({
      ...mensaje,
      title: "Recordatorio",
      description: "El nuevo password del cliente ser : 12345678",
      type: "info",
    });
    setshowalert(true);
     */
    const params = {
      guid: _.get(user, "guid", ""),
      id: id
    };



    dispatch(resetPassword(params));

    setvisible(true);
    
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Form>
        <h3>EDITAR USUARIO</h3>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              {state.avatar ? (
                <img
                  src={`${STORAGE}${state.avatar}`}
                  style={{ width: 100, height: 100, borderRadius: 50 }}
                />
              ) : (
                <span>Usuario no ha cargado imagen de perfil</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="p-3 mb-2 ">
          <Col className="p-3 mb-2 ">
          <Form.Group controlId="formBasicEmail">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="name"
                onChange={onValueChanged}
                value={state.name}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo"
                name="email"
                onChange={onValueChanged}
                value={state.email}
              />
            </Form.Group>


            <Form.Group controlId="formBasicEmail">
              <Form.Label>Dealer (coloque nombre para buscar)</Form.Label>
              <DealerDropDown />
            </Form.Group>


            <Form.Group controlId="formBasicEmail">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Fecha de Nacimiento"
                name="fecha_nacimiento"
                onChange={onValueChanged}
                value={moment(state.fecha_nacimiento).format("ll")}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono"
                name="telefono"
                onChange={onValueChanged}
                value={state.telefono}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Tipo de Usuario</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={state.tipo_user === "1" ? "Usuario" : "Editor"}
              >
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      tipo_user: eventkey,
                      tipo_user_description: "Usuario",
                    });
                  }}
                >
                  Usuario
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      tipo_user: eventkey,
                      tipo_user_description: "Editor",
                    });
                  }}
                >
                  Editor
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Col>
          <Col className="p-3 mb-2 ">

          <Form.Group controlId="formBasicEmail">
              <Form.Label>Tipo de Identificación</Form.Label>

              <DropdownButton
                id="dropdown-basic-button"
                title={
                  Number(state.indentificacion_id) === 1
                    ? "Cédula"
                    : "Pasaporte"
                }
              >
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      indentificacion_id: Number(eventkey),
                    });
                  }}
                >
                  Cédula
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      indentificacion_id: Number(eventkey),
                    });
                  }}
                >
                  Pasaporte
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Número de Identificación</Form.Label>
              <Form.Control
                type="text"
                placeholder="Número de Identificación"
                name="numero_identificacion"
                onChange={onValueChanged}
                value={state.numero_identificacion}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Provincia</Form.Label>
              <ProvinceDropDown />
            </Form.Group>


            <Form.Group controlId="formBasicEmail">
              <Form.Label>Coordinadores</Form.Label>
              <CoordinadoresDropdown />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Operador</Form.Label>
              <DropdownButton id="dropdown-basic-button" title={state.operador}>
                <Dropdown.Item
                  eventKey="1"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Más Móvil",
                    });
                  }}
                >
                  Más Móvil
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Claro",
                    });
                  }}
                >
                  Claro
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Digicel",
                    });
                  }}
                >
                  Digicel
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onSelect={(eventkey, e) => {
                    setstate({
                      ...state,
                      operador_id: Number(eventkey),
                      operador: "Tigo",
                    });
                  }}
                >
                  Tigo
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Activo/Inactivo"
                  checked={state.estado_id === 1 ? true : false}
                  onChange={() => {
                    setstate({
                      ...state,
                      state_id: state.estado_id === 1 ? 2 : 1,
                      estado_id: state.estado_id === 1 ? 2 : 1,
                    });
                  }}
                />
              </Form.Group>
            </Form.Group>
            <Button className="btn btn-danger mt-5 p-2" onClick={handleReset}>
              Resetear Password
            </Button>
          </Col>
        </Row>






        <hr/>
        <Button className="btn btn-success btn-block mt-5 p-2" onClick={save}>
          Editar Usuarios
        </Button>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </Form>
    </div>
  );
}
