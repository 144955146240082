export const GRID_DEFAULT_LOCALE_TEXT = {
  // Root
  rootGridLabel: "grid",
  noRowsLabel: "No rows",
  errorOverlayDefaultLabel: "Ha Ocurrido un error.",

  // Density selector toolbar button text
  toolbarDensity: "Density",
  toolbarDensityLabel: "Density",
  toolbarDensityCompact: "Compact",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Comfortable",

  // Columns selector toolbar button text
  toolbarColumns: "Columnas",
  toolbarColumnsLabel: "Seleccione las columnas",

  // Filters toolbar button text
  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Monstrar Filtros",
  toolbarFiltersTooltipHide: "Esconder Filtros",
  toolbarFiltersTooltipShow: "Mostrar Filtros",
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Descargar como CSV",

  // Columns panel text
  columnsPanelTextFieldLabel: "Buscar Columna",
  columnsPanelTextFieldPlaceholder: "Título de la columna",
  columnsPanelDragIconLabel: "Reordenar Columna",
  columnsPanelShowAllButton: "Monstrar Todo",
  columnsPanelHideAllButton: "Ocultar Todo",

  // Filter panel text
  filterPanelAddFilter: "Agregar Filtro",
  filterPanelDeleteIconLabel: "Borrar",
  filterPanelOperators: "Operador",
  filterPanelOperatorAnd: "Y",
  filterPanelOperatorOr: "O",
  filterPanelColumns: "Columna",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Valor a Filtrar",

  // Filter operators text
  filterOperatorContains: "contiene",
  filterOperatorEquals: "es igual a",
  filterOperatorStartsWith: "inicia con",
  filterOperatorEndsWith: "termina con",
  filterOperatorIs: "es",
  filterOperatorNot: "no es",
  filterOperatorAfter: "es después",
  filterOperatorOnOrAfter: "está en o después",
  filterOperatorBefore: "es antes",
  filterOperatorOnOrBefore: "está en o antes",

  // Filter values text
  filterValueAny: "alguno",
  filterValueTrue: "verdadero",
  filterValueFalse: "falso",

  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar columnas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desordenar",
  columnMenuSortAsc: "Ordenar por ASC",
  columnMenuSortDesc: "Ordenar por DESC",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Ordenar",

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} filas seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,

  // Total rows footer text
  footerTotalRows: "Total De Filas",

  // Checkbox selection text
  checkboxSelectionHeaderName: "Selección",

  // Boolean cell text
  booleanCellTrueLabel: "true",
  booleanCellFalseLabel: "false",
};
