
import { ENDPOINTS } from "../../common/types/Endpoints";
import {postData} from "../../utils/utils"; 
import _ from "lodash"; 
import {SET_ERROR , setError} from "./errorActions"; 


export function loadDashboard(guid: string): any {

    return (dispatch:any) => 
    {
        postData(ENDPOINTS.INITIALREPORT,{guid:guid }).then(resp=>{
            const state = _.get(resp, "status", "error"); 
      /*       console.log("ESTADO"); 
            console.log(resp);
            console.log(state);  */
            if(state==="success")
            {
            dispatch({type: LOAD_REPORT, report: _.get(resp, "message",{})})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch(err=>{ dispatch(setError(err))})
    }
}

export const LOAD_REPORT: string = "LOAD_REPORT";


