import React, { Fragment, Dispatch, useState, useEffect } from "react";
import PaymentsList from "./PaymentsList";
import PaymentsForm from "./PaymentsForm";
import TopCard from "../../common/components/TopCard";
import "./Payments.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IPaymentState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import Popup from "reactjs-popup";
import {
  removePayment,
  clearSelectedPayment,
  setModificationState,
  changeSelectedPayment,
  ExportExcel,
  listpayments,
} from "../../store/actions/payments.action";
import { addNotification } from "../../store/actions/notifications.action";
import {
  PaymentModificationStatus,
  IPayment,
} from "../../store/models/payment.interface";
import _ from "lodash";
import { Link } from "react-router-dom";
import Loading from "../Utilities/Loading";

const Payments: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const payments: IPaymentState = useSelector(
    (state: IStateType) => state.payments
  );

  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );

  const [popup, setPopup] = useState(false);
  const user: string = useSelector((state: IStateType) => state.account.user);
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    dispatch(clearSelectedPayment());
    dispatch(updateCurrentPath("payments", "list"));
  }, [path.area, dispatch]);

  useEffect(() => {
    setvisible(true);
    dispatch(listpayments(_.get(user, "guid", "")));
  }, []);

  useEffect(() => {
    if (payments.payments) {
      if (visible) {
        setvisible(false);
      }
    }
  }, [payments.payments]);

  function onPaymentSelect(simcard: IPayment): void {
    dispatch(changeSelectedPayment(simcard));
    dispatch(setModificationState(PaymentModificationStatus.None));
  }

  function DownladExcel() {
    dispatch(ExportExcel(_.get(user, "guid", "")));
  }

  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Pagos mascomisiones</h1>

      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4">
          <button
            className="btn btn-lg top-button mr-2 float-right"
            onClick={DownladExcel}
          >
            <i className="fas fa-file-excel icon-green"></i> Descargar cabecera de pagos
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Listado</h6>
              <div className="header-buttons">
                <Link to="/addpayment">
                  <button className="btn btn-success btn-green">
                    <i className="fas fa fa-plus"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <PaymentsList onSelect={onPaymentSelect} />
            </div>
          </div>
        </div>
      </div>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Are you sure?</div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!payments.selectedPayment) {
                  return;
                }

                dispatch(removePayment(payments.selectedPayment.id));
                dispatch(clearSelectedPayment());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
      <Loading visible={visible} setvisible={setvisible} />
    </Fragment>
  );
};

export default Payments;
