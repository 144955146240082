import React from "react";
import { useSelector } from "react-redux";
import { IStateType, INewsState } from "../../store/models/root.interface";
import { INews } from "../../store/models/news.interface";
import moment from "moment";
import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid } from '@mui/x-data-grid';
import { GRID_DEFAULT_LOCALE_TEXT } from "../Utilities/LocaleDataGrid";

export const STORAGE = "https://api.comisionesmasmovil.com/storage/";

export type UsuarioListProps = {
  onSelect?: (usuario: INews) => void;
  children?: React.ReactNode;
};

function NewsList(props: UsuarioListProps): JSX.Element {
  const news: INewsState = useSelector((state: IStateType) => state.news);

  console.log(news);

  const noticeElements: (JSX.Element | null)[] = news.news.map((notice) => {
    if (!notice) {
      return null;
    }
    return (
      <tr
        className={`table-row ${
          news.selectedNews && news.selectedNews.id === notice.id
            ? "selected"
            : ""
        }`}
        onClick={() => {
          if (props.onSelect) props.onSelect(notice);
        }}
        key={`usuario_${notice.id}`}
      >
        <th scope="row">{notice.id}</th>
        <td>{notice.titulo}</td>
        <td>{notice.descripcion}</td>
        <td>{moment(notice.created_at).format("ll")}</td>
        <td>
          {
            <img
              src={`${STORAGE}${notice.banner}`}
              style={{ width: 50, height: 50, borderRadius: 25 }}
            />
          }
        </td>
        <td>
          {" "}
          <Link to={`/editdealers`}>
            <BsPencil
              onClick={() =>
                localStorage.setItem("editnews", JSON.stringify(notice))
              }
            />{" "}
          </Link>
        </td>
      </tr>
    );
  });

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 200,
    },
    {
      field: "titulo",
      headerName: "Título",
      width: 200,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      width: 400,
    },
    {
      field: "created_at",
      headerName: "Fecha De Publicación",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography> {moment(params.value).format("ll")}</Typography>
      ),
    },
    {
      field: "banner",
      headerName: "Monto",
      width: 200,
      renderCell: (params: { value: any }) => (
        <img
          src={`${STORAGE}${params.value}`}
          style={{ width: 50, height: 50, borderRadius: 25 }}
        />
      ),
    },

    {
      field: "acciones",
      headerName: "Acciones",
      width: 120,
      renderCell: (params: { value: any }) => (
        <Link
          to={{
            pathname: `/editnews`,
            state: { id: params.value },
          }}
        >
          <EditIcon style={{ fontSize: 25 }} color="primary" />
        </Link>
      ),
      sortable: false,
    },
  ];

  return (
    <div className="table-responsive portlet">
      <Grid container direction="column" style={{ height: "80vh" }}>

        <DataGrid 
        rows={news.news} 
        columns={columns} 
        autoPageSize={true} 
        localeText={GRID_DEFAULT_LOCALE_TEXT}
        onCellClick={(params, event) => {
          localStorage.setItem("editnews", JSON.stringify(params.row))

      }}
        />
      </Grid>
    </div>
  );
}

export default NewsList;
