// errorTypes.js
export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

// errorActions.js
export function setError(error: any) {
    // Verificar si 'error' es un string o un objeto
    if (typeof error === 'string') {
      // Si es un string, se mantiene el comportamiento actual
      return {
        type: SET_ERROR,
        error: error
      };
    } else if (typeof error === 'object' && error !== null) {
      // Si es un objeto, se espera que tenga las propiedades 'error' y 'detalle'
      return {
        type: SET_ERROR,
        error: error.error,  // Aquí se mantiene el mensaje de error como antes
        mensajeerror: error.detalle  // Aquí se agrega el detalle del error
      };
    } else {
      // En caso de recibir un tipo de dato no esperado, puedes decidir cómo manejarlo
      // Por ejemplo, podrías retornar un error genérico o lanzar un error
      return {
        type: SET_ERROR,
        error: 'Error no especificado'
      };
    }
  }
  

export function setHideError(){
    return {
    type: HIDE_ERROR,

    }
   }
