import React from "react";
import { useSelector } from "react-redux";
import { IStateType, ISimCardState } from "../../store/models/root.interface";
import { ISimCard } from "../../store/models/simcard.interface";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { GRID_DEFAULT_LOCALE_TEXT } from "../Utilities/LocaleDataGrid";
import { Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export type UsuarioListProps = {
  onSelect?: (usuario: ISimCard) => void;
  children?: React.ReactNode;
};

function SimCardList(props: UsuarioListProps): JSX.Element {
  const simcards: ISimCardState = useSelector(
    (state: IStateType) => state.simcards
  );

  const simcardElements: (JSX.Element | null)[] = simcards.simcards.map(
    (simcard) => {
      if (!simcard) {
        return null;
      }
      return (
        <tr
          className={`table-row ${
            simcards.selectedSimCard &&
            simcards.selectedSimCard.id === simcard.id
              ? "selected"
              : ""
          }`}
          onClick={() => {
            if (props.onSelect) props.onSelect(simcard);
          }}
          key={`usuario_${simcard.id}`}
        >
          <th scope="row">{simcard.id}</th>
          <td>{simcard.name}</td>
          <td>{moment(simcard.fecha_registro).format("ll")}</td>
          <td>{simcard.comision}</td>
          <td>{simcard.serie}</td>
          <td>{simcard.dealer}</td>
        </tr>
      );
    }
  );

  const columns = [
    {
      field: "serie",
      headerName: "Serie",
      width: 200,
    },
    {
      field: "estado",
      headerName: "Estado",
      renderCell: (params: { value: any }) => (
        <Typography> {params.value==2?'Inactiva':'Activa'}</Typography>
      ),
      width: 200,
    },
    {
      field: "name",
      headerName: "Vendedor",
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Fecha De Registro Simcard",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography> {moment(params.value).format("ll")}</Typography>
      ),
    },
    {
      field: "comision",
      headerName: "Comisión",
      width: 200,
      renderCell: (params: { value: any }) => (
        <Typography> {Number(params.value).toFixed(2)}</Typography>
      ),
    },

    {
      field: "dealer",
      headerName: "Dealer",
      width: 150,
    },
  ];

  return (
    <div className="table-responsive portlet">
      
      <Grid container direction="column" style={{ height: "80vh" }}>
        <DataGrid
          rows={simcards.simcards}
          columns={columns}
          autoPageSize={true}
          localeText={GRID_DEFAULT_LOCALE_TEXT}
        />
      </Grid>
    </div>
  );
}

export default SimCardList;
