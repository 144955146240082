import { ISimCardState, IActionBase } from "../models/root.interface";
import { ADD_SIMCARD, CHANGE_SIMCARD_PENDING_EDIT, EDIT_SIMCARD, REMOVE_SIMCARD,
    CLEAR_SIMCARD_PENDING_EDIT, SET_MODIFICATION_STATE, CHANGE_SIMCARD_COMISION, GET_SIMCARD_LIST} from "../actions/simcard.action";
import { ISimCard, SimCardModificationStatus } from "../models/simcard.interface";



const initialState: ISimCardState = {
    modificationState: SimCardModificationStatus.None,
    selectedSimCard: null,
    simcards: []
};

function simcardsReducer(state: ISimCardState = initialState, action: IActionBase): ISimCardState {
    switch (action.type) {
        case ADD_SIMCARD: {
            let maxId: number = Math.max.apply(Math, state.simcards.map(function(o) { return o.id; }));
            action.simcard.id = maxId + 1;
            return { ...state, simcards: [...state.simcards, action.simcard]};
        }
        case EDIT_SIMCARD: {
            const foundIndex: number = state.simcards.findIndex(pr => pr.id === action.simcard.id);
            let simcards: ISimCard[] = state.simcards;
            simcards[foundIndex] = action.simcard;
            return { ...state, simcards: simcards };
        }
        case REMOVE_SIMCARD: {
            return { ...state, simcards: state.simcards.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_SIMCARD_PENDING_EDIT: {
            return { ...state, selectedSimCard: action.simcard };
        }
        case CLEAR_SIMCARD_PENDING_EDIT: {
            return { ...state, selectedSimCard: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case CHANGE_SIMCARD_COMISION: {
            const foundIndex: number = state.simcards.findIndex(pr => pr.id === action.id);
            let simcards: ISimCard[] = state.simcards;
            return { ...state, simcards: simcards };
        }
        case GET_SIMCARD_LIST: { 
            return {...state, simcards: action.simcards}
        }
        default:
            return state;
    }
}


export default simcardsReducer;
