import { ISimCard, SimCardModificationStatus,ISimCardParameter } from "../models/simcard.interface";
import {postData, getData, postDataExcel} from "../../utils/utils"; 
import _ from "lodash"; 
import {SET_ERROR , setError} from "./errorActions"; 
import { ENDPOINTS } from "../../common/types/Endpoints";
export const ADD_SIMCARD: string = "ADD_SIMCARD";
export const EDIT_SIMCARD: string = "EDIT_SIMCARD";
export const REMOVE_SIMCARD: string = "REMOVE_SIMCARD";
export const CHANGE_SIMCARD_COMISION: string = "CHANGE_SIMCARD_COMISION";
export const CHANGE_SIMCARD_PENDING_EDIT: string = "CHANGE_SIMCARD_PENDING_EDIT";
export const CLEAR_SIMCARD_PENDING_EDIT: string = "CLEAR_SIMCARD_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_SIMCARD_LIST: string = "GET_SIMCARD_LIST";


export function addSimCard(simcard: ISimCard): IAddSimCardActionType {
    return { type: ADD_SIMCARD, simcard: simcard };
}

export function editSimCard(simcard: ISimCard): IEditSimCardActionType {
    return { type: EDIT_SIMCARD, simcard: simcard };
}

export function removeSimCard(id: number): IRemoveSimCardActionType {
    return { type: REMOVE_SIMCARD, id: id };
}

export function changeSimCardComision(id: number, comision: number): IChangeSimCardComisionType {
    return { type: CHANGE_SIMCARD_COMISION, id: id, comision: comision };
}

export function changeSelectedSimCard(simcard: ISimCard): IChangeSelectedSimCardActionType {
    return { type: CHANGE_SIMCARD_PENDING_EDIT, simcard: simcard };
}

export function clearSelectedSimCard(): IClearSelectedSimCardActionType {
    return { type: CLEAR_SIMCARD_PENDING_EDIT };
}

export function setModificationState(value: SimCardModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function listsimcards(parametros:ISimCardParameter)
{
  
console.log(ENDPOINTS.GETSIMCARDSFILTRADAS);
//console.log('papapapapapa',parametros);
    return (dispatch:any) => 
    {
       
      
        postData(ENDPOINTS.GETSIMCARDSFILTRADAS,parametros ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            if(state==="success")
            {
                 dispatch({type: GET_SIMCARD_LIST, simcards: _.get(resp, "message.simcards",[])})
            } else 
            { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    } 
}


export function ExportExcel(parametros:ISimCardParameter){
    
    return (dispatch:any) => 
    {
       // console.log(guid); 
      
        postDataExcel(ENDPOINTS.EXPORTSIMCARDS,parametros).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "SimCards.xls");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }

}

interface IAddSimCardActionType { type: string, simcard: ISimCard };
interface IEditSimCardActionType { type: string, simcard: ISimCard };
interface IRemoveSimCardActionType { type: string, id: number };
interface IChangeSelectedSimCardActionType { type: string, simcard: ISimCard };
interface IClearSelectedSimCardActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  SimCardModificationStatus};
interface IChangeSimCardComisionType {type: string, id: number, comision: number};
