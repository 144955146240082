import { IDealerState, IActionBase } from "../models/root.interface";
import { ADD_DEALER, CHANGE_DEALER_PENDING_EDIT, EDIT_DEALER, REMOVE_DEALER,
    CLEAR_DEALER_PENDING_EDIT, SET_MODIFICATION_STATE, CHANGE_DEALER_COMISION, LIST_DEALERS , GET_DEALERBYID} from "../actions/dealers.action";
import { IDealer, DealerModificationStatus } from "../models/dealer.interface";



const initialState: IDealerState = {
    modificationState: DealerModificationStatus.None,
    selectedDealer: null,
    dealers: [], 
    dealerList: [],
    dealer: {}, 
};

function dealersReducer(state: IDealerState = initialState, action: IActionBase): IDealerState {
    switch (action.type) {
        case ADD_DEALER: {
            let maxId: number = Math.max.apply(Math, state.dealers.map(function(o) { return o.id; }));
            action.dealer.id = maxId + 1;
            return { ...state, dealers: [...state.dealers, action.dealer]};
        }
        case EDIT_DEALER: {
           
            return { ...state, dealer: action.dealer };
        }
        case GET_DEALERBYID: { 
            return {...state, dealer: action.dealer};
        }
        case REMOVE_DEALER: {
            return { ...state, dealers: state.dealers.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_DEALER_PENDING_EDIT: {
            return { ...state, selectedDealer: action.dealer };
        }
        case CLEAR_DEALER_PENDING_EDIT: {
            return { ...state, selectedDealer: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }

        case LIST_DEALERS: {
            return { ...state, dealerList: action.dealers };
        }
        // case CHANGE_DEALER_COMISION: {
        //     const foundIndex: number = state.dealers.findIndex(pr => pr.id === action.id);
        //     let dealers: IDealer[] = state.dealers;
        //     dealers[foundIndex].comision = dealers[foundIndex].comision - action.comision;
        //     return { ...state, dealers: dealers };
        // }
        default:
            return state;
    }
}


export default dealersReducer;
