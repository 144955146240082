import React, { Fragment, Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/* import { updateCurrentPath } from "../../store/actions/root.actions"; */
import TopCard from "../../common/components/TopCard";
import { IUserState, IStateType } from "../../store/models/root.interface";
/* import ProductList from "../Products/ProductsList"; */
import { IOrder } from "../../store/models/order.interface";
/* import OrderList from "../Orders/OrderList"; */
import GroupedBar from "../Chart/GroupedBar";
import { loadDashboard } from "../../store/actions/reports.actions";
import _, {  map } from "lodash";
import moment from "moment";
import Loading from "../Utilities/Loading"; 

moment.locale("es");

interface Idata { 
  labels: string [] ;
  datasets :{ label: string, data: number[], backgroundColor:string } [] ; 
}

const Home: React.FC = () => {
  const user: string = useSelector((state: IStateType) => state.account.user);
  const report: any = useSelector((state: IStateType) => state.report.report);

  const dispatch: Dispatch<any> = useDispatch();

  const [visible, setvisible] = useState<Boolean>(false); 

  const [data, setdata] = useState<Idata> ( {
    labels: [],
    datasets: [
      {
        label: "Sim Cards Escaneados",
        data: [],
        backgroundColor: "#ff4713",
      },
      {
        label: "Comisiones",
        data: [],
        backgroundColor: "#981d97",
      },
    ],
  } );
 

  useEffect(() => {
    setvisible(true);
   // console.log(_.get(user, "guid", ""));
    dispatch(loadDashboard(_.get(user, "guid", "")));
    setvisible(false); 
  }, []);

  useEffect(() => {
    setvisible(true);

   /*  console.log("*****REPORTE DESDE AQUÍ********");
    console.log(report); */
    let labels: string[] = [],
      simcards: number[] = [],
      comisiones: number[] = [];
    const mesames = _.get(report, "MesAMes", []);

    map(mesames, (item) => {
      labels.push(item.mes);
      simcards.push(item.cantidad);
      comisiones.push(item.ganancia);
    });

    data.labels= labels; 
    data.datasets[0].data = simcards;
    data.datasets[1].data = comisiones; 
  /*   console.log("****data*****")
    console.log(data);  */
    setdata({...data})
    setvisible(false); 

  }, [report]);

/*   const users: IUserState = useSelector((state: IStateType) => state.users);
 */

  const orders: IOrder[] = useSelector(
    (state: IStateType) => state.orders.orders
  );



  return (
    <Fragment>
      <h1 className="h2 mb-4 text-gray-800 text-center">
        Bienvenido al Sistema de control Web
      </h1>
      <h3 className="h2 mb-4 text-gray-800 text-center">
        Actualizado a{" "}
        {moment(_.get(report, "resumen.actualizado", "")).format("ll")}
      </h3>
      <div className="container-fluid container-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center cardRounded">
              <div className="card-body">
                <h3 className="card-title">Estado actual del negocio</h3>
                <div className="row">
                  <div className="col-md-6">
                    <TopCard
                      title="Sim Cards Escaneados"
                      text={_.get(report, "resumen.simcards", 0)}
                      icon="sim-card"
                      class="primary"
                    />
                  </div>
                  <div className="col-md-6">
                    <TopCard
                      title="Comisiones"
                      text={_.get(report, "resumen.monto_actual", 0)}
                      icon="credit-card"
                      class="danger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid container-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center cardRounded">
              <div className="card-body">
                <h3 className="card-title">Estado actual del mes</h3>
                <div className="row">
                  <div className="col-md-6">
                    <TopCard
                      title="Sim Cards Escaneados"
                      text={_.get(report, "resumen.activos", 0)}
                      icon="sim-card"
                      class="success"
                    />
                  </div>
                  <div className="col-md-6">
                    <TopCard
                      title="Comisiones"
                      text={_.get(report, "resumen.monto_hist", 0)}
                      icon="credit-card"
                      class="primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card text-center cardRounded">
              <div className="card-body">
                <h3 className="card-title">Histórico Sim Card Vs Comisión</h3>
                <div className="row">
                  { data ? 
                  <GroupedBar data={data} />: 
                  <h3>Cargando...</h3>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  <div className="row">        
        <TopCard
          title="ORDER AMOUNT"
          text={totalOrderAmount.toString()}
          icon="calculator"
          class="danger"
        />
      </div> 

      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Product list</h6>
            </div>
            <div className="card-body">
              <ProductList />
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Order list</h6>
            </div>
            <div className="card-body">
              <OrderList />
            </div>
          </div>
        </div>
      </div>
      */}
      <Loading visible={visible} setvisible={setvisible} />

    </Fragment>
  );
};

export default Home;
