import React, { Fragment, Dispatch, useState, useEffect } from "react";
import NewsList from "./NewsList";
import NewsForm from "./NewsForm";
import TopCard from "../../common/components/TopCard";
import "./News.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import { INewsState, IStateType, IRootPageStateType } from "../../store/models/root.interface";
import Popup from "reactjs-popup";
import { removeNews, clearSelectedNews, setModificationState,
  changeSelectedNews, listNews } from "../../store/actions/news.action";
import { addNotification } from "../../store/actions/notifications.action";
import { NewsModificationStatus, INews } from "../../store/models/news.interface";
import logo from "../../assets/servicios-tv.png";
import {Link} from "react-router-dom"; 
import _ from "lodash"; 

const News: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const news: INewsState = useSelector((state: IStateType) => state.news);
  const path: IRootPageStateType = useSelector((state: IStateType) => state.root.page);
  const user: string = useSelector((state: IStateType) => state.account.user);

  // const totalPrice: number = news.news.reduce((prev, next) => prev + (next.cantidad || 0), 0);
  // const totalAmount: number = news.news.reduce((prev, next) => prev + (next.cantidad || 0), 0);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    dispatch(clearSelectedNews());
    dispatch(updateCurrentPath("news", "list"));
  }, [path.area, dispatch]);

  useEffect(()=>{
    console.log("GUID" +  _.get(user, "guid", "")); 
    dispatch(listNews(   _.get(user, "guid", ""))); 
   
  }, [])

  

  function onNewsSelect(simcard: INews): void {
    dispatch(changeSelectedNews(simcard));
    dispatch(setModificationState(NewsModificationStatus.None));
  }

  function onUsuarioRemove() {
    if(news.selectedNews) {
      setPopup(true);
    }
  }

  return (
    <Fragment>
      <h1 className="h2 mb-2 text-gray-800">Noticias</h1>



      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Listado</h6>
              <div className="header-buttons">
                <Link to="/addNews"> 
                <button className="btn btn-success btn-green" >
                  <i className="fas fa fa-plus"></i>
                </button>
                </Link>
               
              
              </div>
            </div>
            <div className="card-body">
              <NewsList
                onSelect={onNewsSelect}
              />
            </div>
          </div>
        </div>
     
      </div>

    {/* <div className="card mb-3">
      <div className="row no-gutters">
        <div className="col-md-2">
          <img src={logo} className="card-img" alt="..."></img>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <p className="card-text">Nueva Promociones en Más Móvil En Enero</p>
            <p className="card-text purple"><small className="text-muted" style={{color: '#981D97' !, fontWeight: 'bold', fontSize: 16}}>26/01/2021</small></p>
          </div>
        </div>
        <div className="col-md-2">
          <div>TEst</div>
        </div>
      </div>
    </div> */}

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">
            Are you sure?
          </div>
          <div className="popup-content">
            <button type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!news.selectedNews) {
                  return;
                }
                dispatch(addNotification("News removed", `News ${news.selectedNews.titulo} was removed`));
                dispatch(removeNews(news.selectedNews.id));
                dispatch(clearSelectedNews());
                setPopup(false);
              }}>Remove
              </button>
          </div>
        </div>
      </Popup>
    </Fragment >
  );
};

export default News;
