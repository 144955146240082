import { ICommissionState, IActionBase } from "../models/root.interface";
import { ADD_COMMISSION, CHANGE_COMMISSION_PENDING_EDIT, EDIT_COMMISSION, REMOVE_COMMISSION,
    CLEAR_COMMISSION_PENDING_EDIT, SET_MODIFICATION_STATE, CHANGE_COMMISSION_COMISION} from "../actions/commissions.action";
import { ICommission, CommissionModificationStatus } from "../models/commission.interface";



const initialState: ICommissionState = {
    modificationState: CommissionModificationStatus.None,
    selectedCommission: null,
    commissions: [{
        id: 1, nombre: "pagos12011123", cantidad: 320, fecha_registro: "21 Ene 2021", status: "Completado"
    },
    {
        id: 2, nombre: "pagos12022224", cantidad: 150, fecha_registro: "22 Ene 2021", status: "Completado"
    }]
};

function commissionsReducer(state: ICommissionState = initialState, action: IActionBase): ICommissionState {
    switch (action.type) {
        case ADD_COMMISSION: {
            let maxId: number = Math.max.apply(Math, state.commissions.map(function(o) { return o.id; }));
            action.commission.id = maxId + 1;
            return { ...state, commissions: [...state.commissions, action.commission]};
        }
        case EDIT_COMMISSION: {
            const foundIndex: number = state.commissions.findIndex(pr => pr.id === action.commission.id);
            let commissions: ICommission[] = state.commissions;
            commissions[foundIndex] = action.commission;
            return { ...state, commissions: commissions };
        }
        case REMOVE_COMMISSION: {
            return { ...state, commissions: state.commissions.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_COMMISSION_PENDING_EDIT: {
            return { ...state, selectedCommission: action.commission };
        }
        case CLEAR_COMMISSION_PENDING_EDIT: {
            return { ...state, selectedCommission: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case CHANGE_COMMISSION_COMISION: {
            const foundIndex: number = state.commissions.findIndex(pr => pr.id === action.id);
            let commissions: ICommission[] = state.commissions;
            commissions[foundIndex].cantidad = commissions[foundIndex].cantidad - action.cantidad;
            return { ...state, commissions: commissions };
        }
        default:
            return state;
    }
}


export default commissionsReducer;