import React, { Fragment } from "react";
import LeftMenu from "../LeftMenu/LeftMenu";
import TopMenu from "../TopMenu/TopMenu";
import { Switch, Route } from "react-router";
import Users from "../Users/Users";
import Products from "../Products/Products";
import Orders from "../Orders/Orders";
import Home from "../Home/Home";
import Notifications from "../../common/components/Notification";
import Simcards from "../SimCardReport/SimCardReport";
import Payments from "../Payments/Payments";
//import Commissions from "../Commissions/Commissions";
import Dealers from "../Dealers/Dealers";
import News from "../News/News";
import Map from "../Map/Map";
import EditNews from "../News/EditNews";
import AddNews from "../News/AddNews";
import EditDealers from "../Dealers/EditDealers";
import SimcardsPagadas from "../Payments/SimcardsPagadas";
import EditUsers from "../Users/EditUsers";
import PaymentsForm from "../Payments/PaymentsForm";
import ComissionsForm from "../Commissions/CommissionsForm";
import AddUsers from "../Users/AddUsers";

const Admin: React.FC = () => {
  return (
    <Fragment>
      <Notifications />
      <LeftMenu />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopMenu />
          <div className="container-fluid">
            <Switch>
              <Route path={`/editnews`}>
                <EditNews />
              </Route>
              <Route path={`/editusers`}>
                <EditUsers />
              </Route>
              <Route path={`/addusers`}>
                <AddUsers />
              </Route>
              <Route path={`/editdealers`}>
                <EditDealers />
              </Route>
              <Route path={`/simcardspagadas`}>
                <SimcardsPagadas />
              </Route>
              <Route path={`/products`}>
                <Products />
              </Route>
              <Route path={`/users`}>
                <Users />
              </Route>
              <Route path={`/simcards`}>
                <Simcards />
              </Route>
              <Route path={`/payments`}>
                <Payments />
              </Route>
              <Route path={`/commissions`}>
                <ComissionsForm />
              </Route>
              <Route path={`/news`}>
                <News />
              </Route>
              <Route path={`/dealers`}>
                <Dealers />
              </Route>
              <Route path={`/map`}>
                <Map />
              </Route>
              <Route path={`/orders`}>
                <Orders />
              </Route>
              <Route path={`/addnews`}>
                <AddNews />
              </Route>
              <Route path={"/addpayment"}>
                <PaymentsForm />{" "}
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Admin;
