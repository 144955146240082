import { INewsState, IActionBase } from "../models/root.interface";
import { ADD_NEWS, CHANGE_NEWS_PENDING_EDIT, EDIT_NEWS, REMOVE_NEWS,
    CLEAR_NEWS_PENDING_EDIT, SET_MODIFICATION_STATE, CHANGE_NEWS_COMISION , LIST_NEWS , CLEAN_NEW, GET_NEWS_BY_ID} from "../actions/news.action";
import { INews, NewsModificationStatus } from "../models/news.interface";



const initialState: INewsState = {
    modificationState: NewsModificationStatus.None,
    selectedNews: null,
    news: [],
    currentnew: null, 
};

function newsReducer(state: INewsState = initialState, action: IActionBase): INewsState {
    switch (action.type) {
        case ADD_NEWS: {

            return { ...state, currentnew: action.currentnew};
        }
        case EDIT_NEWS: {
   
            return { ...state, currentnew: action.currentnew };
        }
        case REMOVE_NEWS: {
            return { ...state, news: state.news.filter(pr => pr.id !== action.id) };
        }
        case CHANGE_NEWS_PENDING_EDIT: {
            return { ...state, selectedNews: action.news };
        }
        case CLEAR_NEWS_PENDING_EDIT: {
            return { ...state, selectedNews: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case CHANGE_NEWS_COMISION: {
            const foundIndex: number = state.news.findIndex(pr => pr.id === action.id);
            let news: INews[] = state.news;
            return { ...state, news: news };
        }
        case LIST_NEWS : { 
            return {...state, news: action.news}
        }
        case CLEAN_NEW: { 
            return {...state, currentnew: null}
        }
        case GET_NEWS_BY_ID: {
            return {...state, currentnew: action.currentnew}
        }
        default:
            return state;
    }
}


export default newsReducer;
