import React, {
  useState,

  Dispatch,

  ChangeEvent,
  useEffect,
} from "react";
import {
  IStateType
} from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { uploadComissions } from "../../store/actions/commissions.action";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import Alert from "../Utilities/Alert";
import Loading from "../Utilities/Loading";

import { useHistory } from "react-router-dom";


const initialstate = {
  file: {},
};

const CommissionsForm: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const user: string = useSelector((state: IStateType) => state.account.user);

  const error = useSelector((state: IStateType) => state.error.error);

  const [state, setstate] = useState(initialstate);
  const [showalert, setshowalert] = useState(false);
  const [visible, setvisible] = useState(false);
  const history = useHistory();

  const [mensaje, setmensaje] = useState({
    title: "",
    description: "",
    type: "",
  });

  useEffect(() => {
    /* dispatch(cleanPayment());
    dispatch(setHideError());
    dispatch(cleanuploadfile());*/
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      setmensaje({
        ...mensaje,
        title: "Ha ocurrido un error",
        description: "Ha ocurrido un error, favor inténtelo nuevamente",
        type: "danger",
      });
      setshowalert(true);
    }
  }, [error]);

  function closeDialog() {
    history.push("/");
  }
  function saveForm(): void {
    const { file } = state;
    console.log("ARCHIVO");
    console.log(file);
    if (!file) {
      setmensaje({
        ...mensaje,
        title: "Validación de datos",
        description: "Favor Seleccione un archivo",
        type: "warning",
      });
      setshowalert(true);
    } else {
      const commissionheader: any = {
        commissions: state.file,
        guid: _.get(user, "guid", ""),
      };
      dispatch(uploadComissions(commissionheader));
    }
  }

/*   function onValueChanged(e: ChangeEvent<HTMLInputElement>): void {
    setstate({ ...state, [e.target.name]: e.target.value });
    console.log(state);
  } */

  function fileSelectedHandler(e: ChangeEvent<HTMLInputElement>) {
    let files = e.target.files;
    setstate({ ...state, file: files[0] });

    //  dispatch(uploadPaymentDetails(params));
    console.log("Se ejecutó la subida");
  }

  return (
    <Form>
      <div className="col-xl-7 col-lg-7">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              SUBIR ARHCIVO DE COMISIONES
            </h6>
          </div>
          <div className="card-body">
            <Form.Group>
              Favor Suba el Archivo de comisiones<br></br>
              <input
                type="file"
                onChange={fileSelectedHandler}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Form.Group>

            <Button variant="primary" onClick={saveForm}>
              SUBIR COMISIONES
            </Button>
          </div>
        </div>
        <Alert
          title={mensaje.title}
          description={mensaje.description}
          visible={showalert}
          setVisible={setshowalert}
          type={mensaje.type}
          closeDialog={closeDialog}
        />
        <Loading visible={visible} setvisible={setvisible} />
      </div>
    </Form>
  );
};

export default CommissionsForm;
