import { INews, NewsModificationStatus } from "../models/news.interface";
import { ENDPOINTS } from "../../common/types/Endpoints";
import {postData, getData} from "../../utils/utils"; 
import _, { String } from "lodash"; 
import {SET_ERROR , setError} from "./errorActions"; 
import { geteuid } from "node:process";
export const ADD_NEWS: string = "ADD_NEWS";
export const EDIT_NEWS: string = "EDIT_NEWS";
export const REMOVE_NEWS: string = "REMOVE_NEWS";
export const CHANGE_NEWS_COMISION: string = "CHANGE_NEWS_COMISION";
export const CHANGE_NEWS_PENDING_EDIT: string = "CHANGE_NEWS_PENDING_EDIT";
export const CLEAR_NEWS_PENDING_EDIT: string = "CLEAR_NEWS_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const LIST_NEWS: string = "LIST_NEWS"; 
export const CLEAN_NEW: string = "CLEAN_NEW"; 
export const GET_NEWS_BY_ID: string = "GET_NEWS_BY_ID"

export function addNews(news: any): any {

    return (dispatch:any) => 
    {
        let formdata = new FormData();

        formdata.append("banner", news.banner);
        formdata.append("descripcion", news.descripcion);
        formdata.append("titulo", news.titulo);
        formdata.append("url", news.url);
    
        const resultado =  fetch(ENDPOINTS.ADDNEWS, {
            method: "post",
     
            body: formdata,
          })
            .then(async (response) => {
              await response.json().then(async (data) => {
                const state = _.get(data, "status", "error"); 
               if(state ==="success")
               {
               // console.log(data);
                const noticia = _.get(data, "message.noticia", {});
                dispatch({ type: ADD_NEWS, currentnew: noticia });
               }
               else {
                dispatch({ type: SET_ERROR, error: data })

               }
              });
            })
            .catch((err) => {
                dispatch({ type: SET_ERROR, error: err })
            });
    }

}


export function removeNews(id: number): IRemoveNewsActionType {
    return { type: REMOVE_NEWS, id: id };
}

export function changeNewsComision(id: number, comision: number): IChangeNewsComisionType {
    return { type: CHANGE_NEWS_COMISION, id: id, comision: comision };
}

export function changeSelectedNews(news: INews): IChangeSelectedNewsActionType {
    return { type: CHANGE_NEWS_PENDING_EDIT, news: news };
}

export function clearSelectedNews(): IClearSelectedNewsActionType {
    return { type: CLEAR_NEWS_PENDING_EDIT };
}

export function setModificationState(value: NewsModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function listNews (guid: string){
    return (dispatch:any) => 
    {
        postData(ENDPOINTS.GETALLNEWS,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("Estado listar"); 
            console.log(state);
            console.log("Respuesta Listar"); 

            console.log(resp)
            if(state==="success")
            {
            dispatch({type: LIST_NEWS, news: _.get(resp, "message.noticias",[])})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}

export function getNewsById (id: string, guid: string){
    
    return (dispatch:any) => 
    {
        getData(`${ENDPOINTS.GETALLNEWS}/id`,{guid: guid} ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("Estado listar"); 
            console.log(state);
            console.log("Respuesta Listar"); 

            console.log(resp)
            if(state==="success")
            {
            dispatch({type: LIST_NEWS, news: _.get(resp, "message.noticias",[])})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}


export function editNewsActions(id: string, state: any){
    console.log("OBJETO ANTES DE ACTUALIZAR"); 
    console.log(state); 

    return (dispatch:any) => 
    {
        console.log(`${ENDPOINTS.EDITNEWS}${state.id}`); 
        console.log({
            banner: state.banner, 
            descripcion: state.descripcion, 
            titulo: state.titulo, 
            url: state.url, 
            state: state.state_id,
            guid: id,
        })
        postData(`${ENDPOINTS.EDITNEWS}${state.id}`,{
            banner: state.banner, 
            descripcion: state.descripcion, 
            titulo: state.titulo, 
            url: state.url, 
            state: state.state_id,
            guid: id,
        } ).then((resp)=>{
            const state = _.get(resp, "status", "error"); 
            console.log("Estado listar"); 
            console.log(state);
            console.log("Respuesta Listar"); 

            console.log(resp)
            if(state==="success")
            {
            dispatch({type: EDIT_NEWS, currentnew: _.get(resp, "message.noticia",[])})
            } else { 
                dispatch({ type: SET_ERROR, error: resp })
            }
        })
        .catch((err)=>{ console.log(err); 
            dispatch(setError(err))})
    }
}




export function blanknew(){ 
    return {type: CLEAN_NEW }
}

interface IAddNewsActionType { type: string, news: INews };
interface IEditNewsActionType { type: string, news: INews };
interface IRemoveNewsActionType { type: string, id: number };
interface IChangeSelectedNewsActionType { type: string, news: INews };
interface IClearSelectedNewsActionType { type: string };
interface ISetModificationStateActionType { type: string, value:  NewsModificationStatus};
interface IChangeNewsComisionType {type: string, id: number, comision: number};
