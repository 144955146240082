import { SET_ERROR } from './../actions/errorActions';
import { HIDE_ERROR } from './../actions/errorActions';

// errorReducer.js

import { IActionBase } from "../models/root.interface";

const initState = {
    error: {},
    mensajeerror:{}
   
   };
   
   export default function errorReducer(state = initState, action:any){
    const { error } = action;
   
    
    switch(action.type){
        
    case  SET_ERROR:
        
    return {
    error: error,
    mensajeerror:action.mensajeerror

    }

    case HIDE_ERROR: 
    return { 
        ...state, error: { }
    }

    default :
    return state;
   }
}
