export interface ICommission {
    id: number;
    nombre: string;
    cantidad: number;
    fecha_registro: string;
    status: string;
}

export enum CommissionModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}